import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-white text-lg-start py-4" data-aos="fade-up">
      <div className="container py-5 footer-content">
        <div className="row">
          <div className="col-md-3 text-justify">
            <h5>
              <img src="./images/logo.png" alt="Company Logo" />
            </h5>
            <ul className="list-unstyled footer-links">
              <li>
                <p className="footer-logo-text">
                  Innovation Designed for You,
                  <br />
                  Delivered by Us.
                </p>
              </li>
              <li>
                <span
                  className="badge badge-light p-2 mt-2"
                  style={{ fontWeight: "400" }}
                >
                  hello@tdkglobal.com
                </span>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h5>Services</h5>
            <ul className="list-unstyled footer-links">
              <li>
                <Link to="/staff-augmentation">Staff Augmentation</Link>
              </li>
              <li>
                <Link to="/be-spoke">Bespoke Product Development</Link>
              </li>
              <li>
                <Link to="/saas-products">SaaS Products</Link>
              </li>
              <li>
                <Link to="/managed-services">Managed IT Services</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h5>Information</h5>
            <ul className="list-unstyled footer-links">
              <li>
                <Link to="/blog">Case Studies</Link>
              </li>
              <li>
                <Link to="/industries">Industries</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h5>Company</h5>
            <ul className="list-unstyled footer-links">
              <li>
                <Link to="/about">About us</Link>
              </li>
              <li>
                <Link to="/career">Careers</Link>
              </li>
              <li>
                <Link to="/contact">Contact us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-divider">
        <img src="./images/Vector 6.png" alt="Footer Divider" />
      </div>
      <div className="container py-3">
        <div className="d-flex justify-content-between align-items-center">
          <p className="mb-0 footer-font-size-300">Copyright &copy; 2025</p>
          <div className="footer-links">
            <Link to="/privacy" className="mr-4">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
      <img
        src="./images/Vector 5.png"
        alt="Decorative Image"
        className="footer-image"
      />
    </footer>
  );
};

export default Footer;