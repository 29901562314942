import { useEffect, useState } from "react";
import { blogs as blogsData } from "../constant/blog";
const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [currentIndexBlogs, setCurrentIndexBlogs] = useState(0);
  const blogsPerPage = 3;

  useEffect(() => {
    fetchBlogs();
  }, []);

  async function fetchBlogs() {
    // Fetch blogs from API or use a static list
    setBlogs(blogsData);
  }

  const renderInsights = () => {
    const insights = blogs.filter((blog) => blog.isInsight);
    if (insights.length === 0) return null;
    return (
      <>
        <div className="row">
          <div className="col card rounded-lg p-4">
            <a href={`/#/blog-detail?id=${insights[0].id}`}>
              <img
                src={insights[0].image}
                className="img-fluid w-100"
                alt={insights[0].title}
              />
            </a>
            <p className="blogHeading mt-4">{insights[0].title}</p>
            <p className="blogSubHead text-muted">{insights[0].subTitle}</p>
            <div className="text-muted small">
              <i className="fa fa-calendar"></i> {insights[0].date}&nbsp;
              <span className="ms-3">
                <i className="fa fa-clock"></i> {insights[0].readTime}
              </span>
            </div>
          </div>
          <div className="col">
            <ul className="list-group">
              {insights.slice(1).map((blog) => (
                <li
                  key={blog.id}
                  className="list-group-item mb-2 blogCard bg-white"
                >
                  <div className="row p-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="text-muted small">
                        <i className="fa fa-calendar"></i> {blog.date}&nbsp;
                        <span className="ms-3">
                          <i className="fa fa-clock"></i> {blog.readTime}
                        </span>
                      </div>
                      <a href={`/#/blog-detail?id=${blog.id}`}>
                        <img
                          src="./images/right-arrow.png"
                          className="img-fluid"
                          style={{
                            position: "absolute",
                            right: "8%",
                            top: "30%",
                          }}
                        />
                      </a>
                    </div>
                    <p className="mt-10 mb-0"></p>
                    <div className="mt-10 mb-0">{blog.title}</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </>
    );
  };

  const renderRecentBlogs = () => {
    const recentBlogs = blogs.filter((blog) => blog.isRecentBlog);
    const displayedBlogs = recentBlogs.slice(
      0,
      currentIndexBlogs + blogsPerPage
    );
    return (
      <>
        {displayedBlogs.map((blog) => (
          <div key={blog.id} className="card mt-4 p-4" data-aos="fade-up">
            <div className="row no-gutters">
              <div className="col-md-8">
                <div className="card-body">
                  <div className="text-muted small mb-4">
                    <i className="fa fa-calendar"></i> {blog.date}&nbsp;
                    <span className="ms-3">
                      <i className="fa fa-clock"></i> {blog.readTime}
                    </span>
                  </div>
                  <p className="blogHeading mt-2">{blog.title}</p>
                  <p className="blogSubHead text-muted">{blog.subTitle}</p>
                  <p className="learnMore">
                    Learn More{" "}
                    <a href={`/#/blog-detail?id=${blog.id}`}>
                      <img
                        src="./images/right-arrow.png"
                        className="ml-2"
                        height="18px"
                      />
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <img src={blog.image} className="card-img" alt={blog.title} />
              </div>
            </div>
          </div>
        ))}
        {currentIndexBlogs + blogsPerPage < recentBlogs.length && (
          <div className="text-center mt-4">
            <button
              onClick={() =>
                setCurrentIndexBlogs(currentIndexBlogs + blogsPerPage)
              }
              className="btn btn-light btn-load-more radius"
            >
              Load More
            </button>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <section className="our-team py-5 bg-white" data-aos="fade-up">
        <div
          className="container"
          style={{ paddingLeft: "20%", paddingRight: "20%" }}
        >
          <div className="row text-center">
            <div className="col">
              <p className="sliderSubHeading">Blog Article</p>
              <h1 className="mb-5">
                Insights and advice from our expert team.
              </h1>
              <img
                src="./images/Vector 6.png"
                alt="Decorative Image"
                className="staff-diff-bg-image"
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12" style={{ paddingTop: "7%" }}>
              {renderInsights()}
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 bg-white" data-aos="fade-up">
        <div
          className="container"
          style={{ paddingLeft: "20%", paddingRight: "20%" }}
        >
          <div className="row text-center">
            <div className="col">
              <h1 className="mb-5">Recent Blogs</h1>
            </div>
          </div>
        </div>
        <div className="container mt-5">{renderRecentBlogs()}</div>
      </section>
      <hr style={{ marginTop: "0rem", marginBottom: "0rem" }} />
    </>
  );
};

export default Blog;
