const Privacy = () => {
  return (
    <>
      <section class="py-5 bg-white" data-aos="fade-up">
        <div class="privacy">
          <div class="container">
            <h1 class="privacy__title">Privacy Policy</h1>
            <p>Last Modified: January, 2025</p>
            <h2 class="privacy__subtitle">Introduction</h2>
            <p>
              TDK Global ("we", "us", or "Company") respects your privacy and is
              committed to protecting it through our compliance with this
              policy.
            </p>
            <p>
              This Privacy Policy explains how we collect, use, and disclose
              information about you when you use our services or visit our
              website. By using our services or visiting our website, you
              consent to the collection, use, and disclosure of your information
              as described in this Privacy Policy.
            </p>
            <h2 class="privacy__subtitle">Information Gathering</h2>
            <p>
              We may collect information about you when you use our services or
              visit our website, such as your name, email address, and IP
              address. We may also collect information about your usage of our
              services, such as the pages you visit, the actions you take, and
              the time you spend on our website.
            </p>
            <h2 class="privacy__subtitle">Information Usage</h2>
            <p>
              We use the information we collect about you for various purposes,
              including:
            </p>
            <ul>
              <li>Providing and improving our services.</li>
              <li>
                Communicating with you about your account or our services.
              </li>
              <li>Marketing purposes, including sending email campaigns.</li>
              <li>Analyzing the usage of our website and services.</li>
              <li>
                Preventing fraud and ensuring the security of our website and
                services.
              </li>
            </ul>
            <h2 class="privacy__subtitle">Information Storing</h2>
            <p>
              We store the information we collect about you on secure servers.
              We take appropriate security measures to protect against
              unauthorized access, alteration, disclosure, or destruction of
              your personal information.
            </p>
            <h2 class="privacy__subtitle">Cookies</h2>
            <p>
              Our website uses cookies to improve your experience and gather
              information about how you use our website. A cookie is a small
              data file that is stored on your device when you visit our
              website. You can configure your browser to reject cookies or to
              notify you when a website attempts to place a cookie on your
              device. However, if you reject cookies, you may not be able to use
              certain features of our website.
            </p>
            <h2 class="privacy__subtitle">Data Retention</h2>
            <p>
              We retain the information we collect about you for as long as
              necessary to provide you with our services and to comply with our
              legal obligations.
            </p>
            <h2 class="privacy__subtitle">Data Processing and Transfer</h2>
            <p>
              We process your information on servers located in Ukraine. By
              using our services or visiting our website, you consent to the
              processing of your information in Ukraine.
            </p>
            <h2 class="privacy__subtitle">Security</h2>
            <p>
              We use appropriate security measures to protect against
              unauthorized access, alteration, disclosure, or destruction of
              your personal information. Despite these measures, no security
              system is impenetrable, and we cannot guarantee the security of
              our servers or the information stored on them.
            </p>
            <h2 class="privacy__subtitle">Information We Process</h2>
            <p>We process the following information about you:</p>
            <ul>
              <li>
                Personal information, such as your name, email address, and IP
                address.
              </li>
              <li>
                Usage information, such as the pages you visit, the actions you
                take, and the time you spend on our website.
              </li>
              <li>
                Any other information you provide to us through our website or
                services.
              </li>
            </ul>
            <h2 class="privacy__subtitle">Contact Us</h2>
            <p>
              {" "}
              If you have any questions about this Privacy Policy or our
              treatment of your information, please contact us at{" "}
              <a href="mailto:hello@tdkglobal.com">info@tdkglobal.co.uk</a>
            </p>
            <p>
              This Privacy Policy is subject to change at any time, and changes
              will be effective upon posting on our website.
            </p>
          </div>
        </div>
      </section>
      <hr style={{ marginTop: "0rem", marginBottom: "0rem" }}></hr>
    </>
  );
};

export default Privacy;
