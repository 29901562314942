const ManagedItServices = () => {
  return (
    <>
      <section className="slider-sec py-5 bg-white" data-aos="fade-up">
        <div className="">
          <div className="row ">
            <div className="col-md-7" style={{ paddingTop: "7%", paddingLeft: "9%" }}>
              <p className="sliderSubHeading mb-3">MANAGED IT SERVICES</p>
              <h1 className="mb-4">
                Let IT
                <br />
                Drive Business
              </h1>
              <h6 className="mb-4">
                Streamline operations, outsource non-core activities, reduce
                costs, and boost productivity effortlessly.
              </h6>
              <a className="btn btn-dark radius" href="/#/contact">
                Contact Us
              </a>
            </div>
            <div className="col-md-5  text-right">
              <img src="./images/managed-services.png" className="img-fluid" />
            </div>
            <img
              src="./images/Vector 6.png"
              alt="Decorative Image"
              className="slider-image"
            />
          </div>
        </div>
      </section>
      <section className="our-team p-0 bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div
              className="container"
              style={{
                marginTop: "5%",
                paddingLeft: "20%",
                paddingRight: "20%",
              }}
            >
              <div className="row text-center mt-4 mb-4">
                <div className="col">
                  <h1 className="mb-5 font-weight-500">
                    We managed IT
                    <br />
                    <span style={{ color: "#A6C853" }}>
                      {" "}
                      so you can manage business
                    </span>
                  </h1>
                  <img
                    src="./images/Vector 5.png"
                    alt="Decorative Image"
                    className="staff-aug-bg-image"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/bug.png" className="img-fluid" />
                    <p className="staff-card-head mt-4">Incident Management</p>
                    <p className="staff-card-small text-muted">
                      Ensure swift identification, resolution, and prevention of
                      IT disruptions
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/setting-5.png" />
                    <p className="staff-card-head mt-4">App & Infra Monitoring</p>
                    <p className="staff-card-small text-muted">
                      Ensure optimal performance, uptime, and proactive issue
                      resolution
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/shapes.png" />
                    <p className="staff-card-head mt-4">DevOps</p>
                    <p className="staff-card-small text-muted">
                      Accelerates development, enhances collaboration, and
                      ensures seamless software delivery and scalability
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/problem.png" />
                    <p className="staff-card-head mt-4">Problem Management</p>
                    <p className="staff-card-small text-muted">
                      Identify root causes to prevent recurring IT issues and
                      disruptions
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/service.png" className="img-fluid" />
                    <p className="staff-card-head mt-4">Service Transition</p>
                    <p className="staff-card-small text-muted">
                      Ensure smooth handover from development to support for
                      reliable operations
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/hands-holding-child.png" />
                    <p className="staff-card-head mt-4">Business Enablement</p>
                    <p className="staff-card-small text-muted">
                      Shift-left support empowering users, resolves issues
                      faster, and drives business efficiency
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="p-0 bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row grey-bg">
            <div
              className="container"
              style={{
                marginTop: "5%",
                paddingLeft: "20%",
                paddingRight: "20%",
              }}
            >
              <div className="row text-center mb-4">
                <div className="col">
                  <h1 className="mb-5 font-weight-500">
                    Measuring
                    <span style={{ color: "#704AF2" }}>Success.</span>
                  </h1>
                  <img
                    src="./images/Vector 6.png"
                    alt="Decorative Image"
                    className="staff-diff-bg-image measuring-success-overlay"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card-2">
                <div className="row">
                  <div className="col p-3">
                    <p className="staff-card-head-2">Service Availability</p>
                    <p className="staff-card-small-2 text-muted">
                      Guarantees the availability of IT services and systems
                      ensuring no downtime in business.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 blue-bg staff-card-2">
                <div className="row">
                  <div className="col p-3">
                    <p className="staff-card-head-2">Incident Response Time</p>
                    <p className="staff-card-small-2">
                      Ensure quick action when an issue arises
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 green-bg staff-card-2">
                <div className="row">
                  <div className="col p-3">
                    <p className="staff-card-head-2">Resolution Time</p>
                    <p className="staff-card-small-2">
                      Quick timely resolution to help achieve the guaranteed
                      uptime.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 green-bg staff-card-2">
                <div className="row">
                  <div className="col p-3">
                    <p className="staff-card-head-2">Maintenance Compliance</p>
                    <p className="staff-card-small-2">
                      Measure the percentage of scheduled maintenance activities
                      completed within the designated time frame.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card-2">
                <div className="row">
                  <div className="col p-3">
                    <p className="staff-card-head-2">Service Reporting</p>
                    <p className="staff-card-small-2 text-muted">
                      Regular service reports provide objective metrics on
                      service performance, such as uptime, response times, and
                      resolution times
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 blue-bg staff-card-2">
                <div className="row">
                  <div className="col p-3">
                    <p className="staff-card-head-2">Incident Reduction Rate</p>
                    <p className="staff-card-small-2">
                      Lower incident rates mean fewer resources are needed for
                      support and incident resolution, leading to cost savings &
                      service availability.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 mb-4">
              <img src="./images/Vector 5.png" className="img-fluid w-50" />
            </div>
          </div>
        </div>
      </section>
      <hr style={{ marginTop: "2rem", marginBottom: "0rem" }}></hr>
    </>
  );
};

export default ManagedItServices;
