import { useEffect, useState } from "react";
import "./Home.css";
const lines = ["innovation.", "staff.", "services.", "products."];
const lines2 = ["Power Up", "Flex Up", "Scale Up", "Level Up"];
const feedbacks = [
  {
    text: "TDK Global went beyond our ask and provided the thought leadership with their IT & business consultancy to deliver a portal that is well-positioned to meet the evolving needs of the UK-based home care service in the dynamics and competitive healthcare industry.",
    name: "Jason McAteer",
    title: "Co-Founder",
    company: "Mployr",
    image: "./images/comment.png",
    profile: "./images/Vector8.png",
    feedbackImage: "./images/mployr-feedback.png",
  },
  {
    text: "We needed an IT and consulting partner who thinks outside of the box and could digitally transform our existing web-based Computer Aided Facilities Management (CAFM) system which was outdated, disjoint, and far from user-friendly. Working with TDK Global has been a game-changer. From the outset, their team understood our challenges and goals and they delivered a solution that exceeded our expectations.",
    name: "Daniel Whittle",
    title: "Founder",
    company: "STI Facilities",
    image: "./images/comment.png",
    profile: "./images/Vector8.png",
    feedbackImage: "./images/default-feedback.png",
  },
  {
    text: "Finding the right people for your team is always a challenging task as a manager. With TDK Global, that task became less challenging. I loved the fact that TDK global adapted their consultant submission process to better suit our team's needs. Along the way we had really good communication and I am grateful for the fact that Deepak was very patient with me and my slow responses. I would definitely recommend TDK as a reliable partner for your sourcing needs.",
    name: "Head of Engineering",
    title: "",
    company: "Leading car manufacturer",
    image: "./images/comment.png",
    profile: "./images/Vector8.png",
    feedbackImage: "./images/default-feedback.png",
  },
  {
    text: "TDK Global's expertise and experience in the industry were evident in their ability to identify potential issues and implement preventive measures to ensure seamless operations. Their communication was clear, concise, and timely, which made it easy for us to stay on top of the project's progress.",
    name: "Suresh Shah",
    title: "Owner",
    company: "Global IT Consultancy",
    image: "./images/comment.png",
    profile: "./images/Vector8.png",
    feedbackImage: "./images/default-feedback.png",
  },
  {
    text: "Working with TDK Global has been a satisfying experience. Their ability to guide us in our journey towards the organisation vision, has not just allowed us to deliver in quick time but also enabled us to connect well with our target consumers.",
    name: "Kavish Mahadeo",
    title: "Director",
    company: "Leading healthcare provider",
    image: "./images/comment.png",
    profile: "./images/Vector8.png",
    feedbackImage: "./images/default-feedback.png",
  },
  {
    text: "TDK Global has been an invaluable partner in helping us scale our team quickly and effectively through their staff augmentation services. Their ability to provide highly skilled professionals who seamlessly integrate into our projects has been a game-changer. The flexibility and expertise they bring have allowed us to deliver on tight deadlines without compromising quality",
    name: "Engineering Manager",
    title: "",
    company: "Leading Global Furniture Enterprise",
    image: "./images/comment.png",
    profile: "./images/Vector8.png",
    feedbackImage: "./images/default-feedback.png",
  },
];
const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    updateFeedback();
  }, [currentIndex]);

  const updateFeedback = () => {
    const feedbackContainer = document.getElementById("feedback-container");
    const feedbackImage = document.getElementById("feedback-image");
    const feedback = feedbacks[currentIndex];

    if (feedbackContainer) {
      feedbackContainer.innerHTML = `
        <div class="d-flex font-18" style="padding-top: 10%;">
          <img src="${
            feedback.image
          }" alt="Profile Icon" class="feedback-image me-3" />
          <div>
            <div style="padding: 5%; font-weight: 500;">
              ${feedback.text}
            </div>
            <div style="padding: 5%;">
              <img src="${
                feedback.profile
              }" alt="Profile Icon" class="feedback-by me-3" />
              <p>${feedback.name}</p>
              ${
                feedback.title
                  ? `<p><strong>${feedback.title}</strong></p>`
                  : ""
              }
              <p><strong>${feedback.company}</strong></p>
            </div>
          </div>
        </div>
      `;
    }
    if (feedbackImage) {
      feedbackImage.src = feedback.feedbackImage;
    }
  };

  const [animatedText, setAnimatedText] = useState("");
  const [headingText, setHeadingText] = useState(lines2[0]);

  useEffect(() => {
    let index = 0;
    let charIndex = 0;
    let isDeleting = false;

    const typeEffect = () => {
      if (!isDeleting) {
        setAnimatedText(lines[index].substring(0, charIndex));
        charIndex++;
        if (charIndex > lines[index].length) {
          isDeleting = true;
          setTimeout(typeEffect, 1000);
        } else {
          setTimeout(typeEffect, 100);
        }
      } else {
        setAnimatedText(lines[index].substring(0, charIndex));
        charIndex--;
        if (charIndex < 0) {
          isDeleting = false;
          index = (index + 1) % lines.length;
          setTimeout(typeEffect, 500);
        } else {
          setTimeout(typeEffect, 50);
        }
      }
    };
    typeEffect();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setHeadingText(
        (prev) => lines2[(lines2.indexOf(prev) + 1) % lines2.length]
      );
    }, 3500);
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <section className="slider-sec py-5 bg-white" data-aos="fade-up">
        <div className="">
          <div className="row">
            <div
              className="col-md-7"
              style={{ paddingTop: "7%", paddingLeft: "9%" }}
            >
              <p className="sliderSubHeading">UNLOCK POSSIBILITIES</p>
              <h1
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <span style={{ flexBasis: "100%" }}>{headingText}</span>
                <span>with our&nbsp;</span>
                <span>{animatedText}</span>
              </h1>
              <h6 className="mb-4">
                Use TDK Global as your go-to IT solution partner for staffing,
                development, off-the-shelf products, and IT service tailored to
                your needs.
              </h6>
              <a className="btn btn-dark radius" href="/#/contact">
                Contact Us
              </a>
              <div className="row mt-4 p-0 auto-margin">
                <div className="col-4 col-md-3 p-0 slider-icon-div">
                  <img
                    src="./images/check.png"
                    className="img-fluid"
                    alt="Check"
                  />{" "}
                  Flexible
                </div>
                <div className="col-4 col-md-3 p-0 slider-icon-div">
                  <img
                    src="./images/check.png"
                    className="img-fluid"
                    alt="Check"
                  />{" "}
                  Agile
                </div>
                <div className="col-4 col-md-3 p-0 slider-icon-div">
                  <img
                    src="./images/check.png"
                    className="img-fluid"
                    alt="Check"
                  />{" "}
                  Capable
                </div>
              </div>
              <div className="row p-0 auto-margin">
                <div className="col-4 col-md-3 p-0 slider-icon-div">
                  <img
                    src="./images/check.png"
                    className="img-fluid"
                    alt="Check"
                  />{" "}
                  Scalable
                </div>
                <div className="col-4 col-md-3 p-0 slider-icon-div">
                  <img
                    src="./images/check.png"
                    className="img-fluid"
                    alt="Check"
                  />{" "}
                  Future Ready
                </div>
                <div className="col-4 col-md-3 p-0 slider-icon-div">
                  <img
                    src="./images/check.png"
                    className="img-fluid"
                    alt="Check"
                  />{" "}
                  Collaborative
                </div>
              </div>
            </div>
            <div className="col-md-5 text-right">
              <img src="./images/home1.png" className="img-fluid" alt="Home" />
            </div>
            <img
              src="./images/Vector 6.png"
              alt="Decorative Image"
              className="slider-image"
            />
          </div>
        </div>
      </section>
      <hr style={{ marginTop: "0rem", marginBottom: "0rem" }} />
      <section className="py-5 bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <p className="rated-high">Rated Highly</p>
              <p>
                <img src="./images/clutch.png" className="img-fluid" />
              </p>
              <p>
                <img src="./images/ratingFrame.png" className="img-fluid" />
              </p>
            </div>
            <div className="col-md-1 text-center" style={{ paddingTop: "4%" }}>
              <img src="./images/Vector 5.png" className="img-fluid" />
            </div>
            <div
              className="col-md-3 text-center pt-4"
              data-aos="fade-in"
              data-aos-duration="2000"
              data-aos-delay="200"
            >
              <p className="rated-head">300+</p>
              <p className="rated-body">SKILLED IT PROFESSIONALS</p>
            </div>
            <div
              className="col-md-3 text-center pt-4"
              data-aos="fade-in"
              data-aos-duration="2000"
              data-aos-delay="200"
            >
              <p className="rated-head">96%</p>
              <p className="rated-body">customer satısfactıon</p>
            </div>
            <div
              className="col-md-2 text-center pt-4"
              data-aos="fade-in"
              data-aos-duration="2000"
              data-aos-delay="200"
            >
              <p className="rated-head">5+</p>
              <p className="rated-body">GEOGRAPHIES</p>
            </div>
          </div>
        </div>
      </section>
      <hr style={{ marginTop: "0rem", marginBottom: "0rem" }} />
      <section className="our-team py-5 bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row mb-4">
            <div className="col-10">
              <p className="tdk-offering mb-3">TDK GLOBAL OFFERINGS</p>
              <p className="offering-head mb-5">
                Providing scalable
                <br />
                solutions at all times for every need.
              </p>
            </div>
            <div
              className="col-2 text-right justify-content-between align-items-center"
              style={{ paddingTop: "5%" }}
            >
              <img src="./images/boxes2.png" className="img-fluid" />
            </div>
          </div>
          <div className="slider-sec row w-100">
            <div className="center-row-overlay">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="523"
                height="512"
                viewBox="0 0 523 512"
                fill="none"
              >
                <path
                  d="M4.04176 286.21L0.266437 448.418C-0.478566 480.427 24.9202 506.953 56.9318 507.597L232.514 511.132C247.766 511.439 262.521 505.709 273.568 495.19L504.752 275.052C516.618 263.753 523.135 247.951 522.685 231.572L517.871 56.2637C516.994 24.3593 490.443 -0.806714 458.537 0.0263013L287.364 4.49544C272.628 4.88017 258.595 10.8761 248.131 21.2586L21.1287 246.494C10.5136 257.026 4.38971 271.26 4.04176 286.21Z"
                  fill="url(#paint0_linear_24007_2481)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_24007_2481"
                    x1="238.757"
                    y1="7.06514"
                    x2="277.374"
                    y2="509.995"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#62AE6E" />
                    <stop offset="1" stop-color="#EDE14F" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div className="col-md-3"></div>
            <div className="col-md-9">
              <div className="row p-0">
                <div
                  className="col-12 col-md-6 mb-4"
                  data-aos="fade-in"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <div className="card rounded-lg p-4 offering-card">
                    <div className="row">
                      <div className="col">
                        <div className="p-2">
                          <img
                            src="./images/handIcon.png"
                            className="img-fluid"
                          />
                          <p className="offering-card-head mt-2">
                            Bespoke Software Development
                          </p>
                          <p className="offering-card-body">
                            Co-innovate solutions that fit your needs utilising
                            our expertise and know-how.
                          </p>
                          <a
                            href="/#/be-spoke"
                            className="btn btn-light radius beSpokeArrow"
                          >
                            <img
                              src="./images/arrowSideIcon.png"
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 col-md-6 mb-4"
                  data-aos="fade-in"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <div className="card rounded-lg p-4 offering-card">
                    <div className="row">
                      <div className="col">
                        <div className="p-2">
                          <img
                            src="./images/phoneIcon.png"
                            className="img-fluid"
                          />
                          <p className="offering-card-head mt-2">
                            IT team Augmentation
                          </p>
                          <p
                            className="offering-card-body"
                            style={{ paddingTop: "8%" }}
                          >
                            Hire our highly skilled resources to flex your team
                            and build in-house capability.
                          </p>
                          <a
                            href="/#/staff-augmentation"
                            className="btn btn-light radius beSpokeArrow"
                          >
                            <img
                              src="./images/arrowSideIcon.png"
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 col-md-6 mb-4"
                  data-aos="fade-in"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <div className="card rounded-lg p-4 offering-card">
                    <div className="row">
                      <div className="col">
                        <div className="p-2">
                          <img
                            src="./images/settingIcon.png"
                            className="img-fluid"
                          />
                          <p className="offering-card-head mt-2">
                            Off-the-Shelf SaaS products
                          </p>
                          <p className="offering-card-body">
                            Built to plug industry white spaces offering
                            innovation and cost effectiveness.
                          </p>
                          <a
                            href="/#/saas-products"
                            className="btn btn-light radius beSpokeArrow"
                          >
                            <img
                              src="./images/arrowSideIcon.png"
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 col-md-6 mb-4"
                  data-aos="fade-in"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <div className="card rounded-lg p-4 offering-card">
                    <div className="row">
                      <div className="col">
                        <div className="p-2">
                          <img
                            src="./images/staffIcon.png"
                            className="img-fluid"
                          />
                          <p className="offering-card-head mt-2">
                            Managed IT Services
                          </p>
                          <p className="offering-card-body">
                            Focus on things that matter the most by allowing TDK
                            Global to manage your IT services.
                          </p>
                          <a
                            href="/#/managed-services"
                            className="btn btn-light radius beSpokeArrow"
                          >
                            <img
                              src="./images/arrowSideIcon.png"
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              src="./images/Vector 5.png"
              alt="Decorative Image"
              className="offering-image"
            />
          </div>
        </div>
        <div className="container">
          <div className="row" style={{ marginTop: "5%" }}>
            <div className="col-md-6">
              <div className="row p-0 m-0">
                <div className="col-9 p-0">
                  <p className="find-right-text">
                    Find the right service to drive your business metric.
                  </p>
                </div>
                <div className="col-3 p-4">
                  <img src="./images/boxes.png" className="img-fluid" />
                </div>
              </div>
              <div className="row p-0 find-body">
                <div className="col-3 p-0">
                  <img src="./images/check2.png" className="img-fluid" />
                  Skills
                </div>
                <div className="col-3 p-0">
                  <img src="./images/check2.png" className="img-fluid" />
                  Tools
                </div>
                <div className="col-3 p-0">
                  <img src="./images/check2.png" className="img-fluid" />
                  Advice
                </div>
                <div className="col-3 p-0">
                  <img src="./images/check2.png" className="img-fluid" />
                  Products
                </div>
              </div>
              <div className="p-4">
                <img src="./images/contentHome.png" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-6">
              <img src="./images/TDKGLOBAL/grid-1.png" className="img-fluid" />
            </div>
          </div>
        </div>
        <img
          src="./images/Vector 6.png"
          alt="Decorative Image"
          className="scalable-solution-overlay"
        />
      </section>
      <section className="our-team py-5 bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <p className="rated-head text-left">
                Success
                <br />
                Stories
              </p>
            </div>
            <div className="col-md-6">
              <p>
                <img src="./images/star.png" /> &nbsp;
                <img src="./images/star2.png" />
              </p>
              <h6>
                We take pride in talking about some of the value we have added
                to our customers’ business.
              </h6>
            </div>
            <div
              className="col-md-2 d-flex flex-column justify-content-end align-items-end"
              style={{ paddingBottom: "4%" }}
            >
              <a className="btn btn-dark radius" href="/#/blog">
                Show All
              </a>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-4"
              data-aos="fade-in"
              data-aos-duration="500"
              data-aos-delay="200"
            >
              <div className="card rounded-lg success-story-card">
                <div className="row p-2">
                  <div className="col">
                    <img
                      src="./images/blog2.png"
                      className="img-fluid custom-card-img"
                    />
                    <div className="p-3">
                      <div className="card-light-stories">January 12, 2024</div>
                      <p className="card-head-stories mt-2">
                        Bespoke platform to connect care homes with carers
                      </p>
                      <p className="card-head-body">
                        See how a leading healthcare provider partnered with TDK
                        Global to build a web platform to bring carers and
                        carehomes closer.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-4"
              data-aos="fade-in"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <div className="card rounded-lg success-story-card">
                <div className="row p-2">
                  <div className="col">
                    <img
                      src="./images/blog3.png"
                      className="img-fluid custom-card-img"
                    />
                    <div className="p-3">
                      <div className="card-light-stories">
                        November 12, 2024
                      </div>
                      <p className="card-head-stories mt-2">
                        An AI-powered CRM tool for the recruitment industry
                      </p>
                      <p className="card-head-body">
                        Discover how we've developed a cloud-based SaaS solution
                        that streamlines recruitment processes, enabling
                        recruiters to optimize their time and enhance
                        productivity in their daily workflow tasks.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-4"
              data-aos="fade-in"
              data-aos-duration="1500"
              data-aos-delay="200"
            >
              <div className="card rounded-lg success-story-card">
                <div className="row p-2">
                  <div className="col">
                    <img
                      src="./images/blog4.png"
                      className="img-fluid custom-card-img"
                    />
                    <div className="p-3">
                      <div className="card-light-stories">January 14, 2025</div>
                      <p className="card-head-stories mt-2">
                        Seamless IT Team Augmentation
                      </p>
                      <p className="card-head-body">
                        Discover how TDK Global's IT team augmentation
                        capability helped organizations of all sizes achieve
                        greater efficiency in their ongoing delivery projects,
                        boosting performance.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="p-0 bg-white mt-4 border-0 mb-4" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col">
                  <img
                    src="./images/Vector 5.png"
                    className="img-fluid h-50 w-10"
                    alt="vector"
                  />
                </div>
                <div className="col text-right">
                  <p className="offering-head">Voice of Customers</p>
                </div>
              </div>
            </div>
            <div className="col mployr-feedback">
              <div className="row">
                <div
                  className="col-md-7 success-story-text"
                  id="feedback-container"
                ></div>
                <div className="col-md-5">
                  <img
                    src="./images/mployr-feedback.png"
                    className="img-fluid"
                    id="feedback-image"
                    alt="feedback"
                  />
                </div>
              </div>
              <div className="row text-center button-feedback">
                <button
                  className="carousel-btn carousel-btn-prev"
                  onClick={() =>
                    setCurrentIndex(
                      (currentIndex - 1 + feedbacks.length) % feedbacks.length
                    )
                  }
                >
                  <img
                    src="./images/arrow-left.png"
                    className="img-fluid"
                    alt="prev"
                  />
                </button>
                <button
                  className="carousel-btn carousel-btn-next"
                  onClick={() =>
                    setCurrentIndex((currentIndex + 1) % feedbacks.length)
                  }
                >
                  <img
                    src="./images/arrow-right.png"
                    className="img-fluid"
                    alt="next"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-team py-5 bg-white" data-aos="fade-up">
        <div className="container">
          <div
            className="row text-center mt-4 mb-4"
            style={{ paddingLeft: "20%", paddingRight: "20%" }}
          >
            <div className="col">
              <p>
                <img className="mr-2" src="./images/plus.png" />
                &nbsp;
                <img src="./images/star2.png" />
              </p>
              <p className="power-up-head mb-4">
                Power your business
                <br />
                with TDK Global
              </p>
              <h6>
                TDK Global has the ability to bring flexibility, affordability
                and
                <br />
                assurance in service.
              </h6>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <img src="./images/cube3.png" />
                <p className="etho-right-card-head mt-4">
                  Expertise Across the Tech Spectrum
                </p>
                <p className="etho-right-card-body">
                  Our team consists of seasoned developers, consultants, and
                  experts who excel in various areas of technology.
                </p>
              </div>
              <div
                className="mt-4"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <img src="./images/cube.png" />
                <p className="etho-right-card-head mt-4">
                  Great Colloaboration
                </p>
                <p className="etho-right-card-body">
                  We believe in delivering as a team. We bring a one-team
                  approach with digital tools for collaboration and delivery.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <img src="./images/indexGroup.png" className="img-fluid" />
            </div>
            <div className="col-md-3">
              <div
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <img src="./images/cube2.png" />
                <p className="etho-right-card-head mt-4">
                  Flexible <br /> Engagement
                </p>
                <p className="etho-right-card-body">
                  We understand no two projects are similar. So we will tailor
                  solutions for your unique needs.
                  <br /> .
                </p>
              </div>
              <div
                className="mt-4"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <img src="./images/cube4.png" />
                <p className="etho-right-card-head mt-4">Outcome First Model</p>
                <p className="etho-right-card-body">
                  We adopt an outcome based delivery model where measuring
                  success is paramount.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="p-4 bg-white" data-aos="fade-up">
        <div className="container">
          <div
            className="row align-items-center"
            style={{ padding: "0% 15% 5% 15%" }}
          >
            <div className="col-md-3 text-right">
              <img
                src="./images/Cyber-Essentials-Logo-1 1.png"
                style={{ height: "100px" }}
                alt="Cyber Essentials"
                className="img-fluid"
              />
            </div>
            <div className="col-md-9">
              <p className="mb-0 cyber-home-text">
                Our commitment towards customers is evident from our Cyber
                Essentials Certification aimed to protect customer data against
                threats.
              </p>
            </div>
          </div>
        </div>
        <img
          src="./images/Vector 5.png"
          alt="Decorative Image"
          className="decorative-image-style"
        />
      </section>
      <section className="partner-section" data-aos="fade-up">
        <p className="partner-head">Partner & Customer Network</p>
        <p className="partner-body mb-4">Growing steadily brick by brick</p>

        <marquee BEHAVIOR="standard" direction="left">
          <img
            src="./images/mployr_logo.png"
            className="image-partner corousal-img mx-2"
            alt="Mployr"
          />
          <img
            src="./images/volvo_logo.png"
            className="image-partner corousal-img mx-2"
            alt="Volvo"
          />
          <img
            src="./images/microsoft_logo.png"
            className="image-partner corousal-img mx-2"
            alt="Microsoft"
          />
          <img
            src="./images/softwareag_logo.png"
            className="image-partner corousal-img mx-2"
            alt="Software AG"
          />
          <img
            src="./images/rapid_logo.png"
            className="image-partner corousal-img mx-2"
            alt="Rapid Resourcing"
          />
          <img
            src="./images/splunk_logo.png"
            className="image-partner corousal-img mx-2"
            alt="Splunk"
          />
          <img
            src="./images/aws_logo.png"
            className="image-partner corousal-img mx-2"
            alt="AWS"
          />
          <img
            src="./images/sti_logo.png"
            className="image-partner corousal-img mx-2"
            alt="STI"
          />
          <img
            src="./images/lfuk_logo1.png"
            className="image-partner corousal-img mx-2"
            alt="LFUK"
          />
          <img
              src="./images/hubspot.png"
              className="image-partner corousal-img mx-2"
              alt="HUBSPOT"
          />
          <img
              src="./images/FCL.png"
              className="image-partner corousal-img mx-2"
              alt="FCL"
          />
        </marquee>
        <div className="col-12 join-us-container">
          <a
            className="btn btn-dark join-us-btn mt-4 contact-header-btn"
            href="/#/contact"
          >
            Join Us
          </a>
        </div>
        <img
          src="./images/Vector 6.png"
          alt="Decorative Image"
          className="decorative-image-style-2"
        />
      </section>
      <section className="location-image-section" data-aos="fade-up">
        <img src="./images/Group 37.png" alt="Our Locations" />
      </section>
      <hr style={{ marginTop: "0rem", marginBottom: "0rem" }} />
    </>
  );
};

export default Home;
