import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <div className="top-bar bg-black py-2">
        <div className="container">
          <div className="d-flex justify-content-end align-items-center">
            <a
              href="https://x.com/TDK_Global"
              className="text-white mx-2"
              title="Twitter"
            >
              <i className="fa-brands fa-x-twitter"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/93326709/admin/dashboard/"
              className="text-white mx-2"
              title="LinkedIn"
            >
              <i className="fa-brands fa-linkedin-in"></i>
            </a>
            <a
              href="https://www.instagram.com/tdkglobal/"
              className="text-white mx-2"
              title="Instagram"
            >
              <i className="fa-brands fa-instagram"></i>
            </a>
            <a
              href="https://www.youtube.com/@TDKGlobal"
              className="text-white mx-2"
              title="YouTube"
            >
              <i className="fa-brands fa-youtube"></i>
            </a>
            <a
              href="https://www.facebook.com/people/TDK-Global/61572818284732/"
              className="text-white mx-2"
              title="YouTube"
            >
              <i className="fa-brands fa-facebook-f"></i>
            </a>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-dark">
        <Link className="navbar-brand" to="/" title="TDK Global">
          <img src="./images/logo.png" alt="TDK Global Logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fa-solid fa-align-justify"></i>
        </button>
        <div
          className="collapse navbar-collapse justify-content-center"
          id="navbarNav"
        >
          <ul className="navbar-nav text-center">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">
                About Us
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="/staff-augmentation"
                id="servicesDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Services
              </Link>
              <div className="dropdown-menu" aria-labelledby="servicesDropdown">
                <Link className="dropdown-item" to="/be-spoke">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="17"
                    viewBox="0 0 18 17"
                    fill="none"
                  >
                    <path
                      d="M13.5 0.25C13.775 0.25 14 0.475 14 0.75V2.25H15.5C15.775 2.25 16 2.475 16 2.75C16 3.025 15.775 3.25 15.5 3.25H14V4.75C14 5.025 13.775 5.25 13.5 5.25C13.225 5.25 13 5.025 13 4.75V3.25H11.5C11.225 3.25 11 3.025 11 2.75C11 2.475 11.225 2.25 11.5 2.25H13V0.75C13 0.475 13.225 0.25 13.5 0.25ZM13.5 9.25C13.775 9.25 14 9.475 14 9.75V11.25H15.5C15.775 11.25 16 11.475 16 11.75C16 12.025 15.775 12.25 15.5 12.25H14V13.75C14 14.025 13.775 14.25 13.5 14.25C13.225 14.25 13 14.025 13 13.75V12.25H11.5C11.225 12.25 11 12.025 11 11.75C11 11.475 11.225 11.25 11.5 11.25H13V9.75C13 9.475 13.225 9.25 13.5 9.25ZM4.275 6.0125L1.69375 7.20625L4.275 8.39688C4.49062 8.49688 4.6625 8.66875 4.7625 8.88437L5.95312 11.4656L7.14375 8.88437C7.24375 8.66875 7.41563 8.49688 7.63125 8.39688L10.2125 7.20625L7.63125 6.01562C7.41563 5.91563 7.24375 5.74375 7.14375 5.52812L5.95312 2.94688L4.7625 5.52812C4.6625 5.74375 4.49062 5.91563 4.275 6.01562V6.0125ZM3.85625 9.30625L0.290625 7.65938C0.1125 7.57812 0 7.4 0 7.20625C0 7.0125 0.1125 6.83438 0.290625 6.75313L3.85625 5.10625L5.5 1.54063C5.58125 1.3625 5.75938 1.25 5.95312 1.25C6.14687 1.25 6.325 1.3625 6.40625 1.54063L8.05313 5.10625L11.6187 6.75C11.7969 6.83125 11.9094 7.00938 11.9094 7.20312C11.9094 7.39687 11.7969 7.575 11.6187 7.65625L8.05625 9.30625L6.40938 12.8687C6.32812 13.0469 6.15 13.1594 5.95625 13.1594C5.7625 13.1594 5.58438 13.0469 5.50313 12.8687L3.85625 9.30625Z"
                      fill="black"
                    />
                  </svg>
                  Bespoke Development
                </Link>
                <Link className="dropdown-item" to="/staff-augmentation">
                  <i className="fa-regular fa-user mr-2"></i>Staff Augmentation
                </Link>
                <Link className="dropdown-item" to="/saas-products">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                  >
                    <path
                      d="M12.2286 1.39286H8.57143V4.82143H14.4964L13.275 2.07143C13.0929 1.65714 12.6821 1.39286 12.2321 1.39286H12.2286ZM14.8571 5.96429H8.57143H7.42857H1.14286V13.9643C1.14286 14.5964 1.65357 15.1071 2.28571 15.1071H13.7143C14.3464 15.1071 14.8571 14.5964 14.8571 13.9643V5.96429ZM1.50357 4.82143H7.42857V1.39286H3.77143C3.32143 1.39286 2.91071 1.65714 2.72857 2.07143L1.50357 4.82143ZM12.2286 0.25C13.1321 0.25 13.95 0.782143 14.3179 1.60714L15.8036 4.95C15.9321 5.24286 16 5.55714 16 5.87857V13.9643C16 15.225 14.975 16.25 13.7143 16.25H2.28571C1.025 16.25 0 15.225 0 13.9643V5.87857C0 5.55714 0.0678571 5.24286 0.196429 4.95L1.68214 1.60714C2.05 0.782143 2.86786 0.25 3.77143 0.25H12.2286Z"
                      fill="black"
                    />
                  </svg>
                  SaaS Products
                </Link>
                <Link className="dropdown-item" to="/managed-services">
                  <i className="fa-regular fa-handshake mr-2"></i>Managed IT
                  Services
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/industries">
                Industries
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/blog">
                Blog
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/media">
                Media
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/career">
                Career
              </Link>
            </li>
          </ul>
        </div>
        <Link
          className="btn btn-dark btn-sm contact-header-btn ml-auto"
          to="/contact"
        >
          Contact Us
        </Link>
      </nav>
    </>
  );
};

export default Header;
