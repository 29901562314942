import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { blogs as blogsData } from "../constant/blog";

import "./BlogDetail.css";

const feedbacks = [
  {
    text: "TDK Global went beyond our ask and provided the thought leadership with their IT & business consultancy to deliver a portal that is well-positioned to meet the evolving needs of the UK-based home care service in the dynamics and competitive healthcare industry.",
    name: "Lucy McAteer",
    title: "Co-Founder",
    company: "Mployr",
    image: "./images/comment.png",
    profile: "./images/Vector8.png",
    feedbackImage: "./images/default-feedback.png",
  },
  {
    text: "TDK Global has been an invaluable partner in helping us scale our team quickly and effectively through their staff augmentation services. Their ability to provide highly skilled professionals who seamlessly integrate into our projects has been a game-changer. The flexibility and expertise they bring have allowed us to deliver on tight deadlines without compromising quality",
    name: "Engineering Manager",
    title: "",
    company: "Leading Global Furniture Enterprise",
    image: "./images/comment.png",
    profile: "./images/Vector8.png",
    feedbackImage: "./images/default-feedback.png",
  },
];

const BlogDetail = () => {
  const location = useLocation();
  const [blog, setBlog] = useState(null);
  const [currentFeedbackIndex, setCurrentFeedbackIndex] = useState(0);

  useEffect(() => {
    const fetchBlogs = () => {
      const urlParams = new URLSearchParams(location.search);
      const id = urlParams.get("id");
      const selectedBlog = blogsData.find((blog) => blog.id == id);
      if (selectedBlog) {
        setBlog(selectedBlog);
        document.title = selectedBlog.meta.description;

        let metaTag = document.querySelector("meta[name='description']");
        if (metaTag) {
          metaTag.setAttribute("content", selectedBlog.meta.description);
        } else {
          metaTag = document.createElement("meta");
          metaTag.name = "description";
          metaTag.content = selectedBlog.meta.description;
          document.head.appendChild(metaTag);
        }
      }
    };
    fetchBlogs();
  }, [location.search]);

  useEffect(() => {
    const updateFeedback = () => {
      setCurrentFeedbackIndex(
        (prevIndex) => (prevIndex + 1) % feedbacks.length
      );
    };

    const interval = setInterval(updateFeedback, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <section className="our-team py-5 bg-white" id="blogDetails">
        {blog && (
          <section className="py-5" data-aos="fade-up">
            <div className="container">
              <div className="card bg-light">
                <section data-aos="fade-up">
                  <div className="card">
                    <img src={blog.image} className="img-fluid" alt="Blog" />
                  </div>
                </section>
              </div>
            </div>
          </section>
        )}
      </section>

      <section className="our-team py-5 bg-white">
        <div className="container" id="blog-description">
          {blog && (
            <div dangerouslySetInnerHTML={{ __html: blog.description }} />
          )}
        </div>
      </section>

      <section
        className="p-0 bg-white mb-4 border-0 mb-4 mt-4"
        data-aos="fade-up"
      >
        <div className="container">
          <div
            className="row"
            style={{
              borderRadius: "35.386px",
              background: "rgba(24, 24, 27, 0.06)",
            }}
          >
            <div className="col">
              <div className="row">
                <div
                  className="col-md-7 success-story-text"
                  id="feedback-container"
                >
                  <div className="d-flex font-18" style={{ paddingTop: "10%" }}>
                    <img
                      src={feedbacks[currentFeedbackIndex].image}
                      alt="Profile Icon"
                      className="feedback-image me-3"
                    />
                    <div>
                      <div style={{ padding: "5%", fontWeight: 500 }}>
                        {feedbacks[currentFeedbackIndex].text}
                      </div>
                      <div style={{ padding: "5%" }}>
                        <img
                          src={feedbacks[currentFeedbackIndex].profile}
                          alt="Profile Icon"
                          className="feedback-by me-3"
                        />
                        <p>{feedbacks[currentFeedbackIndex].name}</p>
                        {feedbacks[currentFeedbackIndex].title && (
                          <p>
                            <strong>
                              {feedbacks[currentFeedbackIndex].title}
                            </strong>
                          </p>
                        )}
                        <p>
                          <strong>
                            {feedbacks[currentFeedbackIndex].company}
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <img
                    src={feedbacks[currentFeedbackIndex].feedbackImage}
                    className="img-fluid"
                    id="feedback-image"
                    alt="Feedback"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr style={{ marginTop: "5rem", marginBottom: "0rem" }}></hr>
    </>
  );
};

export default BlogDetail;
