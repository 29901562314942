const About = () => {
  return (
    <>
      <section className="p-5 bg-white">
        <div className="container">
          <div className="">
            <div className="row">
              <img
                src="./images/Vector 5.png"
                alt="Decorative Image"
                className="about-border-image"
              />

              <div className="col-md-12">
                <p className="about-us-head">ABOUT US</p>
              </div>
              <div className="col-md-8 about-us-section">
                <p className="about-us-subHead">
                  For more than eight years, we've collaborated with customers
                  to swiftly expand their teams and also build solutions to
                  address their business obstacles.
                </p>

                <p className="about-us-subHead2">
                  At TDK Global, we are driven by the belief that technology can
                  transform businesses and elevate their potential. Founded in
                  2017, we have been on a mission to empower organisations with
                  tailored solutions that drive growth, efficiency, and success.
                </p>
              </div>
              <div className="col-md-4">
                <img
                  src="./images/Main Image.png"
                  className="img-fluid"
                  alt="About Us Image"
                />
              </div>
            </div>
          </div>

          <div className="">
            <div className="row">
              <img
                src="./images/Vector 6.png"
                alt="Decorative Image"
                className="story-border-image2"
              />
              <div className="col-md-12">
                <p className="about-us-head">OUR STORY</p>
              </div>
              <div className="col-md-8 our-story-section">
                <p className="about-us-subHead2-story p-0">
                  Our journey began when a group of passionate technologists saw
                  the white space in the industry to provide flexible and agile
                  solutions. We had a pool of contextual champions and IT
                  experts with cross-industry know-how. With a shared vision, we
                  embarked on a mission to bridge the gap between cutting-edge
                  technology/skills and businesses seeking to harness its power.
                  Over the years, we have evolved into a team of experts who
                  thrive on challenges, creativity, and collaboration.
                </p>

                <div className="row align-items-center mt-5">
                  <div className="col-md-3 text-right border-left">
                    <div className="cyber-image"></div>
                  </div>
                  <div className="col-md-9">
                    <p className="mb-0 cyber-p">
                      Our commitment towards customers is evident from our Cyber
                      Essentials Certification aimed to protect customer data
                      against threats.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <img
                  src="./images/Main Image (1).png"
                  className="img-fluid"
                  alt="Our Story Image"
                />
              </div>
            </div>
          </div>
          <div className="our-story-section mt-5" style={{ marginLeft: "-1.4%" }}>
            <img
              src="./images/Ellipse 2.png"
              alt="Decorative Image"
              className="story-border-image4"
            />
            <div className="row">
              <div className="col-md-4 mb-4">
                <div className="card rounded-lg p-4 about-card">
                  <div className="row">
                    <div className="col p-3">
                      <p className="about-card-head-part">Established in</p>
                      <p className="about-card-head mt-2">2017</p>
                      <p className="about-card-head-body">
                        The vision to offer top-quality technical talent
                        anywhere in the world was born.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-md-4 mb-4">
                <div className="card rounded-lg p-4 about-card">
                  <div className="row">
                    <div className="col p-3">
                      <p className="about-card-head mt-2">300+</p>
                      <p className="about-card-head-body">
                        IT Experts with varied technical skills and contextual
                        knowledge.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card rounded-lg p-4 about-card">
                  <div className="row">
                    <div className="col p-3">
                      <p className="about-card-head mt-2">5+</p>
                      <p className="about-card-head-body">
                        Cities world wide offering global reach and onshore,
                        nearshore, offshore capability.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="card rounded-lg p-4 about-card">
                  <div className="row">
                    <div className="col p-3">
                      <p className="about-card-head mt-2">96%</p>
                      <p className="about-card-head-body">
                        Customer Satisfaction Index with high repeat business.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-4 mb-4">
                <div className="card rounded-lg p-4 about-card">
                  <div className="row">
                    <div className="col p-3">
                      <p className="about-card-head mt-2">Liverpool, UK</p>
                      <p className="about-card-head-body">
                        Headquartered in Northwest UK and delivery centres in EU
                        and India.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              src="./images/Ellipse 5.png"
              alt="Decorative Image"
              className="story-border-image5"
            />
          </div>
        </div>
      </section>
      <section className="our-team py-5 bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="company-etho-head-part">our values</p>
              <h1 className="mb-5">
                Our Company’s
                <br />
                Ethos
              </h1>
            </div>
            <div className="col">
              <p>
                <img src="./images/star.png" /> &nbsp;{" "}
                <img src="./images/star2.png" />
              </p>
              <h6>
                Collaboration fuels innovation and drives success. TDK Global
                fosters a culture of seamless collaboration.
              </h6>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 etho-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/cap.png" />
                    <p className="etho-card-head mt-4">Our Mission</p>
                    <p className="etho-card-body">
                      Join the ranks of forward-thinking businesses that are
                      embracing the future of software solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 etho-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/cap2.png" />
                    <p className="etho-card-head mt-4">Our Values</p>
                    <p className="etho-card-body">
                      Customer Centricity
                      <br />
                      Innovation at core
                      <br />
                      Open Communication
                      <br />
                      Collaboration
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 pl-4 pr-4">
              <p className="etho-right-card-head">Flexibility</p>
              <p className="etho-right-card-body">
                We understand no two requirements can be similar and hence we
                tailor our offerings to suit your needs and cashflow.
              </p>
              <p className="etho-right-card-head">Agility</p>
              <p className="etho-right-card-body">
                With technology and industry moving at lightning pace, speed is
                of great essence. We employ toolkits for faster Go-to-market.
              </p>
              <p className="etho-right-card-head">Scalability</p>
              <p className="etho-right-card-body">
                Your satisfaction is our mission. Our dedicated support team is
                here to assist you every step of the way.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="our-team py-5 bg-white" data-aos="fade-up">
        <div className="container">
          <p className="our-team-head-part">Our Team</p>
          <h1 className="text-center mb-5">
            The team <br />
            behind TDK Global.
          </h1>
          <div className="row">
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 etho-card">
                <div className="row">
                  <div className="col-4">
                    <img
                      src="./images/deepak.png"
                      className="card-img-top"
                      alt="Team Member 1"
                    />
                  </div>
                  <div className="col-8">
                    <p className="etho-card-head">Deepak Keshari</p>
                    <p className="etho-card-head-part">Founder and CEO</p>
                  </div>
                </div>
                <hr style={{ padding: "0", marginBottom: "20px" }} />
                <p className="etho-card-head-part">
                  A technologist by both passion and profession, Deepak believes
                  that technology offers limitless opportunities. He is
                  convinced that, as humans, we are far better equipped to
                  improve the world with it than without it.
                </p>
                <ul className="list-unstyled d-flex mt-2 mb-0">
                  <li className="pr-4 border-right">
                    <a
                      href="https://www.linkedin.com/in/keshari/"
                      className="text-dark"
                      title="Linked In"
                    >
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 etho-card">
                <div className="row">
                  <div className="col-4">
                    <img
                      src="./images/momita.png"
                      className="card-img-top"
                      alt="Team Member 2"
                    />
                  </div>
                  <div className="col-8">
                    <p className="etho-card-head">Momita Choudhury</p>
                    <p className="etho-card-head-part">Chief Growth Officer</p>
                  </div>
                </div>
                <hr style={{ padding: "0", marginBottom: "20px" }} />
                <p className="etho-card-head-part">
                  Passionate, cross-functional, creative and results-driven
                  professional with over 14 years of technology sales and
                  marketing experience.
                </p>
                <ul className="list-unstyled d-flex mt-2 mb-0">
                  <li className="pr-4 border-right">
                    <a
                      href="https://www.linkedin.com/in/momita-saha-choudhury/"
                      className="text-dark"
                      title="Linked In"
                    >
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 etho-card">
                <div className="row">
                  <div className="col-4">
                    <img
                      src="./images/sandro.png"
                      className="card-img-top"
                      alt="Team Member 3"
                    />
                  </div>
                  <div className="col-8">
                    <p className="etho-card-head">Sandro Maia</p>
                    <p className="etho-card-head-part">Group Product Manager</p>
                  </div>
                </div>
                <hr style={{ padding: "0", marginBottom: "20px" }} />
                <p className="etho-card-head-part">
                  Passionate about digital product management, his main skills
                  lie in delivering value by creating solutions to solve real
                  problems.
                </p>
                <ul className="list-unstyled d-flex mt-2 mb-0">
                  <li className="pr-4 border-right">
                    <a
                      href="https://www.linkedin.com/in/sandromaia/"
                      className="text-dark"
                      title="Linked In"
                    >
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="location-image-section" data-aos="fade-up">
        <img src="./images/Group 37.png" alt="Our Locations" />
      </section>
      <hr />
    </>
  );
};

export default About;
