const MediaComp = () => {
  return (
    <>
      <section className="media-bg p-0 bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div
              className="container"
              style={{
                marginTop: "5%",
                paddingLeft: "20%",
                paddingRight: "20%",
              }}
            >
              <div className="row text-center mt-4 mb-4">
                <div className="col">
                  <p className="sliderSubHeading">IN MEDIA</p>
                  <h1 className="mb-5">
                    Discover us
                    <br />
                    in action.
                  </h1>
                  <img
                    src="./images/Vector 6.png"
                    alt="Decorative Image"
                    className="staff-diff-bg-image"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-2">
              <div className="row m-0 p-0">
                <div className="col-12 mt-4">
                  <div className="card" style={{ position: "relative" }}>
                    <div className="card-body">
                      <iframe
                        style={{ width: "100%" }}
                        src="https://www.linkedin.com/embed/feed/update/urn:li:share:7292102301756850176"
                        height="940"
                        width="504"
                        frameborder="0"
                        allowfullscreen=""
                        title="Embedded post"
                      ></iframe>{" "}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="card" style={{ position: "relative" }}>
                    <div className="card-body">
                      <iframe
                        style={{ width: "100%" }}
                        src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7285205423706578944"
                        height="640"
                        width="504"
                        frameborder="0"
                        allowfullscreen=""
                        title="Embedded post"
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-4">
                  <div className="card" style={{ position: "relative" }}>
                    <div className="card-body">
                      <iframe
                        style={{ width: "100%" }}
                        src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7286909538450952192"
                        height="900"
                        width="504"
                        frameborder="0"
                        allowfullscreen=""
                        title="Embedded post"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-md-4 mb-2"
              style={{ marginTop: "30px", marginBottom: "-30px" }}
            >
              <div className="row m-0 p-0">
                <div className="col-12">
                  <div className="card" style={{ position: "relative" }}>
                    <div className="card-body">
                      <iframe
                        style={{ width: "100%" }}
                        src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7280511147110256640"
                        height="850"
                        width="504"
                        frameborder="0"
                        allowfullscreen=""
                        title="Embedded post"
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-4">
                  <div className="card" style={{ position: "relative" }}>
                    <div className="card-body">
                      <iframe
                        style={{ width: "100%" }}
                        src="https://www.linkedin.com/embed/feed/update/urn:li:share:7287215270488608768"
                        height="920"
                        width="504"
                        frameborder="0"
                        allowfullscreen=""
                        title="Embedded post"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-2">
              <div className="row m-0 p-0">
                <div className="col-12">
                  <div className="card" style={{ position: "relative" }}>
                    <div className="card-body">
                      <iframe
                        style={{ width: "100%" }}
                        src="https://www.linkedin.com/embed/feed/update/urn:li:share:7284330615477313536"
                        height="816"
                        width="504"
                        frameborder="0"
                        allowfullscreen=""
                        title="Embedded post"
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-4">
                  <div className="card" style={{ position: "relative" }}>
                    <div className="card-body">
                      <iframe
                        style={{ width: "100%" }}
                        src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7283447284053245952"
                        height="1150"
                        width="504"
                        frameborder="0"
                        allowfullscreen=""
                        title="Embedded post"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <hr style={{ marginTop: "0rem", marginBottom: "0rem" }} />
    </>
  );
};

export default MediaComp;
