import "./Industry.css";
const Industry = () => {
  return (
    <>
      <section className="slider-sec py-5 bg-white" data-aos="fade-up">
        <div className="">
          <div className="row">
            <div
              className="col-md-7"
              style={{ paddingTop: "7%", paddingLeft: "9%" }}
            >
              <p className="sliderSubHeading">INDUSTRY SPREAD</p>
              <h1 className="mb-4">
                Bridging Vision
                <br />
                and Innovation.
              </h1>
              <h6 className="mb-4">
                Learn how we have been empowering organisations across
                industries bringing efficiency and driving innovation.
              </h6>
              <a className="btn btn-dark radius" href="/#/contact">
                Contact Us
              </a>
            </div>
            <div className="col-md-5 text-right">
              <img src="./images/industries.png" className="img-fluid" />
            </div>
            <img
              src="./images/Vector 6.png"
              alt="Decorative Image"
              className="slider-image industry-slider"
            />
          </div>
        </div>
      </section>
      <section className="p-5 bg-white" data-aos="fade-up">
        <div className="container">
          <div className="industry-about-us-section mb-5">
            <img
              src="./images/Vector 6.png"
              alt="Decorative Image"
              className="industry-border-image"
            />
            <p className="yourPartner">YOUR PARTNER IN TECHNOLOGY EXCELLENCE</p>
            <div className="row">
              <div className="col-md-7 font-weight-500">
                <p>
                  TDK Global has been supporting organisations in industries
                  such as manufacturing, retail, care service, professional
                  services, and the public sector by{" "}
                  <span className="text-primary">
                    delivering tailored technology strategies and innovative
                    solutions.
                  </span>
                </p>
                <p>
                  With a focus on optimising operations, enhancing digital
                  transformation, and{" "}
                  <span className="text-primary">
                    addressing unique business challenges
                  </span>
                  , TDK Global ensures its clients achieve their goals with
                  efficiency and reliability.
                </p>
                <p>
                  By leveraging expertise in staff augmentation, bespoke
                  software development, SaaS products, and managed IT services,
                  TDK Global enhances operational efficiency, drives innovation,
                  and
                  <span className="text-primary">
                    delivers measurable results to meet the unique needs of each
                    sector
                  </span>
                  .
                </p>
              </div>
              <div className="col-md-5">
                <img
                  src="./images/indMainImage.png"
                  className="img-fluid"
                  alt="About Us Image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="p-0 bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div
              className="container"
              style={{
                marginTop: "5%",
                paddingLeft: "20%",
                paddingRight: "20%",
              }}
            >
              <div className="row text-center mt-4 mb-4">
                <div className="col">
                  <h1 className="mb-5 font-weight-500">
                    Key Industries
                    <span style={{ color: "#A6C853" }}>we serve</span>
                  </h1>
                  <img
                    src="./images/Vector 5.png"
                    alt="Decorative Image"
                    className="industry-bg-image"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="card rounded-lg p-4 keyIndustryCard">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/homeicon.png" />
                    <h5 className="mt-4">Care Service</h5>
                    <p>
                      <small>
                        Provides solutions to organisations providing vital
                        services to society’s most vulnerable people and
                        families
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="card rounded-lg p-4 keyIndustryCard">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/conveyor-belt.png" />
                    <h5 className="mt-4">Manufacturing</h5>
                    <p>
                      <small>
                        From car manufacturers to consumer product group
                        manufacturers.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="card rounded-lg p-4 keyIndustryCard">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/cart.png" />
                    <h5 className="mt-4">Retail</h5>
                    <p>
                      <small>
                        Utilising our cutting edge solutions and skills to help
                        streamline retail operations.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="card rounded-lg p-4 keyIndustryCard">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/building-columns.png" />
                    <h5 className="mt-4">Public Sector</h5>
                    <p>
                      <small>
                        Create beautiful, pixel-perfect, and easy-to-use designs
                        that
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="card rounded-lg p-4 keyIndustryCard">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/people-group.png" />
                    <h5 className="mt-4">Professional Service</h5>
                    <p>
                      <small>
                        Custom & off-shelf-products for wide array of
                        organisations such as finance, legal, technology
                        services.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="card rounded-lg p-4 keyIndustryCard">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/buildings.png" />
                    <h5 className="mt-4">Real Estate</h5>
                    <p>
                      <small>
                        Property management with asset register, compliance
                        management and surveys.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="card rounded-lg p-4 keyIndustryCard">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/travel.png" />
                    <h5 className="mt-4">Travel and Transportation</h5>
                    <p>
                      <small>
                        Driving innovation and efficiency across travel and
                        transportation industries.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="card rounded-lg p-4 keyIndustryCard">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/energy.png" />
                    <h5 className="mt-4">Energy and Utilities</h5>
                    <p>
                      <small>
                        Empowering energy and utility sectors with sustainable
                        and innovative solutions.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ marginTop: "8%" }} id="valueChain" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col">
              <h5 className="mb-3">
                <small>OUR CAPABILITIES</small>
              </h5>
              <h1 className="mb-5">
                Value Chain
                <br />
                Mapping
              </h1>
            </div>
            <div className="col">
              <p>
                <img src="./images/star.png" />
                &nbsp;
                <img src="./images/star2.png" />
              </p>
              <p>
                Following capability mapping is suggestive in nature. We can
                build tailored made offerings to suit your requirement.
              </p>
            </div>
          </div>
          <div className="row p-0 w-50">
            <div className="col-6 text-left">
              <label for="toggleSwitch" className="toggle-label">
                Value Chain
              </label>
            </div>
            <div className="col-6 text-right">
              <label for="toggleSwitch" className="toggle-label">
                Overlap Offerings
              </label>
            </div>
            <div className="col text-center">
              <div className="toggle-container mobile-container">
                <input
                  type="checkbox"
                  id="toggleSwitch"
                  className="toggle-checkbox"
                />
                <label className="toggle-slider" for="toggleSwitch"></label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col text-center mt-3">
              <img
                id="valueChainImage"
                src="./images/value chain 1.png"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="p-0 bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div
              className="container"
              style={{
                marginTop: "5%",
                paddingLeft: "20%",
                paddingRight: "20%",
              }}
            >
              <div className="row text-center mt-4 mb-4">
                <div className="col">
                  <h1 className="mb-5 font-weight-500">
                    Delivering Value,
                    <br />
                    Creating a{" "}
                    <span style={{ color: "#704AF2" }}>Difference</span>
                  </h1>
                  <img
                    src="./images/Vector 6.png"
                    alt="Decorative Image"
                    className="creative-diff-bg-image"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 deliveringCard">
                <div className="row">
                  <div className="col p-3">
                    <h5>Co-Innovate</h5>
                    <p>
                      <small>
                        We believe in co-innovating solutions with customers
                        through extensive collaboration.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 blue-bg deliveringCard">
                <div className="row">
                  <div className="col p-3">
                    <h5>Contextual Knowledge</h5>
                    <p>
                      <small>
                        Our workforce brings the contextual knowledge to guide
                        you and bridge the gap between business and technology.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 green-bg deliveringCard">
                <div className="row">
                  <div className="col p-3">
                    <h5>Outcome Driven</h5>
                    <p>
                      <small>
                        Outcome based model focuses on measuring the success
                        through business metrics instead of standard IT metrics.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 green-bg deliveringCard">
                <div className="row">
                  <div className="col p-3">
                    <h5>Automation First</h5>
                    <p>
                      <small>
                        TDK Global adopts an automation first approach to
                        eliminate non value added manual tasks and release
                        bandwidth for what really matters.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 deliveringCard">
                <div className="row">
                  <div className="col p-3">
                    <h5>Industry Best Practices</h5>
                    <p>
                      <small>
                        Our contextual champions bring the industry best
                        practices to ensure you stay abreast with industry
                        leaders & influencers.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 blue-bg deliveringCard">
                <div className="row">
                  <div className="col p-3">
                    <h5>Plug White Spaces</h5>
                    <p>
                      <small>
                        Determine right solutions to plug the white spaces and
                        be the innovators in the crowded market.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 mb-4">
              <img src="./images/Vector 5.png" className="img-fluid w-50" />
            </div>
          </div>
        </div>
      </section>
      <hr style={{ marginTop: "0rem", marginBottom: "0rem" }} />
    </>
  );
};

export default Industry;
