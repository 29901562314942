import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import "./Book.css";

const Book = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    product: "",
  });
  const [submittedData, setSubmittedData] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    generateTimeSlots();
  }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmittedData(formData);
    setShowConfirmation(true);
  };

  const generateTimeSlots = () => {
    const slots = [];
    const timeInterval = 30; // 30-minute intervals
    const startHour = 9;
    const endHour = 17;

    for (let hour = startHour; hour < endHour; hour++) {
      for (let minute = 0; minute < 60; minute += timeInterval) {
        const formattedTime = `${String(hour).padStart(2, "0")}:${String(
          minute
        ).padStart(2, "0")}`;
        slots.push(formattedTime);
      }
    }
    setTimeSlots(slots);
  };

  const handleTimeSlotSelection = (time) => {
    setSelectedTime(time);
  };

  const handleBookDemo = async () => {
    if (!selectedDate || !selectedTime) {
      Swal.fire("Error", "Please select both a date and time slot.", "error");
      return;
    }

    const { name, email, product } = formData;
    const comment = `Please schedule a demo for ${product} at ${selectedDate} on ${selectedTime}`;
    const payload = {
      user_displayName: name,
      email_address: email,
      html_text: comment,
      mailto: "tdkglobal",
    };

    try {
      const response = await fetch(
        "https://func-mail-svc-prod.azurewebsites.net/api/emailsvc?code=EHbqPrBZJYpcThLK2Lmkz6JkG0nDHWFIs5ujYIftEwtgAzFuV-uvbA%3D%3D",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        Swal.fire(
          "Success!",
          `We have scheduled a demo with you on ${selectedDate} at ${selectedTime}.`,
          "success"
        );
      } else {
        Swal.fire("Oops!", "Something went wrong.", "error");
      }
    } catch (error) {
      Swal.fire("Oops!", "Something went wrong.", "error");
    }
  };

  return (
    <>
      <section className="py-5 bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="sliderSubHeading">BOOK A DEMO</p>
              <h1 className="mb-5">
                See what you can get
                <br />
                off-our-shelf!
              </h1>
            </div>
            <div className="col">
              <p>
                <img src="./images/star.png" alt="star" />
                &nbsp;
                <img src="./images/star2.png" alt="star" />
              </p>
              <p className="our-team-p">
                We will be happy to take you through our SaaS products and help
                you understand how you leverage the functionalities to further
                enhance your operations.
              </p>
            </div>
          </div>
        </div>
      </section>

      {!showConfirmation ? (
        <section
          id="bookDemoSection"
          className="py-5 bg-white"
          data-aos="fade-up"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <form
                  id="bookDemoForm"
                  className="border p-4"
                  onSubmit={handleSubmit}
                >
                  <p className="book-head">Enter Details</p>
                  <div className="mb-3">
                    <label htmlFor="name" className="book-label">
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="form-control"
                      required
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="book-label">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      required
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="product" className="book-label">
                      Product
                    </label>
                    <select
                      id="product"
                      className="form-control"
                      required
                      onChange={handleInputChange}
                    >
                      <option value="">Select a product</option>
                      <option value="Talent Trail">
                        Talent Trail (AI Powered Recruitment CRM)
                      </option>
                      <option value="CAFM">
                        CAFM (One Stop Solution For Your Property Management)
                      </option>
                    </select>
                  </div>
                  <h6>
                    <small>
                      By proceeding, you confirm that you have read and agree
                      to Calendly's{" "}
                      <a
                        href="https://calendly.com/legal/customer-terms-conditions"
                        target="_blank"
                      >
                        Terms of Use
                      </a>
                       and 
                      <a
                        href="https://calendly.com/legal/privacy-notice"
                        target="_blank"
                      >
                        Privacy Notice
                      </a>
                      .
                    </small>
                  </h6>
                  <button
                    type="submit"
                    className="btn btn-primary radius"
                    style={{ backgroundColor: "#0069FF" }}
                  >
                    Schedule Event
                  </button>
                  <p className="mt-4">
                    <img src="./images/calendly.png" className="img-fluid" />
                  </p>
                </form>
              </div>
              <div className="col-md-7">
                <img
                  src="./images/CRM.png"
                  alt="Placeholder"
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section id="confirmationSection" className="py-5 bg-light">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <h5>Your Details</h5>
                <p>
                  <strong>Name:</strong> {submittedData?.name}
                  <br />
                  <strong>Email:</strong> {submittedData?.email}
                  <br />
                  <strong>Product:</strong> {submittedData?.product}
                </p>
              </div>
              <div className="col-md-4">
                <h5>Select Date</h5>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
              </div>
              <div className="col-md-4">
                <h5>Select Time Slot</h5>
                {timeSlots.map((time) => (
                  <button
                    key={time}
                    className={`btn btn-outline-primary m-1 ${
                      selectedTime === time ? "active" : ""
                    }`}
                    onClick={() => handleTimeSlotSelection(time)}
                  >
                    {time}
                  </button>
                ))}
                <button
                  className="btn btn-success mt-3"
                  onClick={handleBookDemo}
                >
                  Book Demo
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Book;
