import { HashRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./component/About";
import Footer from "./component/Footer";
import Header from "./component/Header";
import Home from "./component/Home";
import MediaComp from "./component/MediaComp";
import Career from "./component/Career";
import Industry from "./component/Industry";
import Contact from "./component/Contact";
import BeSpoke from "./component/BeSpoke";
import StaffAug from "./component/StaffAug";
import SasProduct from "./component/SasProduct";
import ManagedItServices from "./component/ManagedItServices";
import Book from "./component/Book";
import Blog from "./component/Blog";
import BlogDetail from "./component/BlogDetail";
import Privacy from "./component/Privacy";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/media" element={<MediaComp />} />
        <Route path="/career" element={<Career />} />
        <Route path="/industries" element={<Industry />} />
        <Route path="/be-spoke" element={<BeSpoke />} />
        <Route path="/staff-augmentation" element={<StaffAug />} />
        <Route path="/saas-products" element={<SasProduct />} />
        <Route path="/managed-services" element={<ManagedItServices />} />
        <Route path="/book" element={<Book />} />
        <Route path="/blog-detail" element={<BlogDetail />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
