import { useState } from "react";

const sections = {
  'Compliance Management' : {
    title: "Compliance Management at your fingertips",
    image: "./images/crm-bg.png",
    subtitle: "Stay Compliant, Stay Confident",
    description:
      "A comprehensive solution for managing the complexities of property compliance",
    mockup: "./images/iPhoneMockup.png",
    features: [
      {
        icon: "./images/cube3.png",
        title: "Surveys and Inspections",
        desc: "Schedule, conduct, and record property surveys and inspections with ease.",
      },
      {
        icon: "./images/cube.png",
        title: "Asset Management",
        desc: "Efficiently track, manage, and maintain property assets throughout their lifecycle.",
      },
      {
        icon: "./images/cube2.png",
        title: "Project and Maintenance",
        desc: "Oversee property-related projects from planning to execution.",
      },
      {
        icon: "./images/cube4.png",
        title: "Reporting and Analytics",
        desc: "Generate detailed reports catering to specific compliance needs.",
      },
    ],
  },
  'Recruitment CRM': {
    title: "Recruitment CRM for Smart Hiring",
    image: "./images/crm-main.png",
    subtitle: "Hire Smarter, Grow Faster",
    description:
      "An AI-powered hiring solution to streamline your recruitment process.",
    mockup: "./images/iPhonecrm.png",
    features: [
      {
        icon: "./images/cube3.png",
        title: "Applicant Tracking",
        desc: "Manage candidate pipelines with ease and efficiency.",
      },
      {
        icon: "./images/cube.png",
        title: "Interview Scheduling",
        desc: "Automate interview coordination with seamless integrations.",
      },
      {
        icon: "./images/cube2.png",
        title: "Job Posting",
        desc: "Post jobs to multiple platforms instantly.",
      },
      {
        icon: "./images/cube4.png",
        title: "HR Analytics",
        desc: "Gain insights into your hiring trends and efficiency.",
      },
    ],
  },
  "Carehome Service Portal": {
    title: "Carehome Service Portal",
    image: "./images/mployr-main.png",
    subtitle: "Providing Quality Care, Effortlessly",
    description:
      "A digital solution for managing care home operations seamlessly.",
    mockup: "./images/iphoneCarehome.png",
    features: [
      {
        icon: "./images/cube3.png",
        title: "Resident Management",
        desc: "Keep track of resident records and medical history.",
      },
      {
        icon: "./images/cube.png",
        title: "Staff Scheduling",
        desc: "Manage staff shifts and ensure optimal care coverage.",
      },
      {
        icon: "./images/cube2.png",
        title: "Care Plans",
        desc: "Customize and monitor individual care plans.",
      },
      {
        icon: "./images/cube4.png",
        title: "Compliance & Reporting",
        desc: "Ensure adherence to regulations and generate reports effortlessly.",
      },
    ],
  },
};

const SasProduct = () => {
  const [activeSection, setActiveSection] = useState("Compliance Management");
  const content = sections[activeSection];
  return (
    <>
      <section className="slider-sec py-5 bg-white" data-aos="fade-up">
        <div className="">
          <div className="row ">
            <div
              className="col-md-7"
              style={{ paddingTop: "7%", paddingLeft: "9%" }}
            >
              <p className="sliderSubHeading">OFF-THE-SHELF PRODUCTS</p>
              <h1 className="mb-5">
                Future-Ready
                <br />
                Solutions for a
                <br />
                Smarter Tomorrow.
              </h1>
              <h6 className="mb-4">
                Cutting-edge off-the-shelf SaaS solutions designed to help
                businesses thrive in a fast-paced digital world providing
                scalability, flexibility, and ease of integration.{" "}
              </h6>
              <a className="btn btn-dark radius" href="/#/book">
                Schedule Demo
              </a>
            </div>
            <div className="col-md-5  text-right">
              <img src="./images/CRM.png" className="img-fluid" />
            </div>
            <img
              src="./images/Vector 6.png"
              alt="Decorative Image"
              className="slider-image"
            />
          </div>
        </div>
      </section>
      <section className="p-0 bg-white mt-4 border-0" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="card rounded-lg p-4 w-100 border-0 bg-light">
                <div className="container">
                  <div className="mt-2">
                    <h5>
                      <img
                        src="./images/Vector 6.png"
                        style={{ height: "50px" }}
                      />
                      Our Solutions
                    </h5>
                  </div>
                  <div className="mt-4 mb-4">
                    {Object.keys(sections).map((key) => (
                      <button
                        key={key}
                        className={`btn mr-2 solution-btn ${
                          activeSection === key
                            ? "btn-primary active"
                            : "bg-white btn-light"
                        }`}
                        onClick={() => setActiveSection(key)}
                      >
                        {key.replace(/\b\w/g, (c) => c.toUpperCase())}
                      </button>
                    ))}
                  </div>
                  <div id="solution-content">
                    <div className="row text-center mt-4 mb-4">
                      <div className="col">
                        <h1 className="mb-5 font2 section-title">
                          Compliance Management <br /> at your fingertips
                        </h1>
                        <p>
                          <img
                            src="./images/crm-bg.png"
                            className="img-fluid section-image"
                          />
                        </p>
                      </div>
                    </div>
                    <div
                      className="row text-center mt-4 mb-4"
                      style={{
                        paddingLeft: "20%",
                        paddingRight: "20%",
                      }}
                    >
                      <div className="col">
                        <h1 className="mb-5 font2 section-subtitle">
                          Stay Compliant, <br /> Stay Confident
                        </h1>
                        <p>
                          <small className="section-description">
                            A comprehensive solution for managing the
                            complexities of <br />
                            property compliance
                          </small>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 mb-4">
                        <div>
                          <img src="./images/cube3.png" className="feature-icon" />
                          <h5 className="mt-4 feature-title">
                            Surveys and Inspections
                          </h5>
                          <p>
                            <small className="feature-description">
                              Schedule, conduct, and record property surveys and
                              inspections with ease. Ensure compliance with
                              regulatory standards.
                            </small>
                          </p>
                        </div>
                        <div className="mt-4">
                          <img src="./images/cube.png" className="feature-icon" />
                          <h5 className="mt-4 feature-title">Asset Management</h5>
                          <p>
                            <small className="feature-description">
                              Efficiently track, manage, and maintain property
                              assets throughout their lifecycle.
                            </small>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 mb-4">
                        <img
                          src="./images/iPhoneMockup.png"
                          className="img-fluid section-mockup"
                        />
                      </div>
                      <div className="col-md-4 mb-4">
                        <div>
                          <img src="./images/cube2.png" className="feature-icon" />
                          <h5 className="mt-4 feature-title">
                            Project and Maintenance
                          </h5>
                          <p>
                            <small className="feature-description">
                              Oversee property-related projects from planning to
                              execution.
                            </small>
                          </p>
                        </div>
                        <div className="mt-4">
                          <img src="./images/cube4.png" className="feature-icon" />
                          <h5 className="mt-4 feature-title">
                            Reporting and Analytics
                          </h5>
                          <p>
                            <small className="feature-description">
                              Generate detailed reports catering to specific
                              compliance needs.
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="p-0 bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div
              className="container"
              style={{
                marginTop: "5%",
                paddingLeft: "20%",
                paddingRight: "20%",
              }}
            >
              <div className="row text-center mt-4 mb-4">
                <div className="col">
                  <h1 className="mb-5 font-weight-500">
                    An offering that bundles product with
                    <span style={{ color: "#A6C853" }}>Services</span>
                  </h1>
                  <img
                    src="./images/Vector 5.png"
                    alt="Decorative Image"
                    className="offer-bg-image"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 sass-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/mobile.png" />
                    <h5 className="mt-4">Mobile Ready</h5>
                    <p>
                      <small>
                        All our products are mobile & tablet ready. With
                        responsive screens, track things on-the-go no matter
                        which device you have.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 sass-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/database.png" />
                    <h5 className="mt-4">Data Migration</h5>
                    <p>
                      <small>
                        Be it legacy system or excel based data, we will migrate
                        the data for you so you dont have to worry about
                        historical data.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 sass-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/users-between-lines.png" />
                    <h5 className="mt-4">Change Management</h5>
                    <p>
                      <small>
                        We understand how stressful adopting new change could
                        be. Therefore, we offer training for all so you spend
                        more time doing what’s important.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 sass-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/arrow-progress.png" />
                    <h5 className="mt-4">Simple Processes</h5>
                    <p>
                      <small>
                        All our products are built with one main objective - to
                        simplify the operations and get more out of it. Hence,
                        we have usability at core.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 sass-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/life-ring.png" />
                    <h5 className="mt-4">Hypercare Support</h5>
                    <p>
                      <small>
                        TDK Global believes in handholding and being their for
                        you while you stabilise the new ways of working.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 sass-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/Primary.png" />
                    <h5 className="mt-4">Release Pipeline</h5>
                    <p>
                      <small>
                        We will create a release based plan to ensure we drop
                        new functionalities to keep up with the industry trend.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr style={{ marginTop: "0rem", marginBottom: "0rem" }}></hr>
    </>
  );
};

export default SasProduct;
