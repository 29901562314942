const BeSpoke = () => {
  return (
    <>
      <section className="slider-sec py-5 bg-white" data-aos="fade-up">
        <div className="">
          <div className="row">
            <div
              className="col-md-7"
              style={{ paddingTop: "7%", paddingLeft: "9%" }}
            >
              <p className="sliderSubHeading mb-3">BESPOKE DEVELOPMENT</p>
              <h1 className="mb-4">
                Tailored Products,
                <br />
                Exceptional Results.
              </h1>
              <h6 className="mb-4">
                We specialize in bespoke cross platform product development,
                creating tailored solutions that align perfectly with your
                unique business needs and objectives.
              </h6>
              <a className="btn btn-dark radius" href="/#/contact">
                Contact Us
              </a>
            </div>
            <div className="col-md-5 text-right">
              <img src="./images/bespoke.png" className="img-fluid" />
            </div>
            <img
              src="./images/Vector 6.png"
              alt="Decorative Image"
              className="slider-image slider-image-bespoke"
            />
          </div>
        </div>
      </section>
      <section className="our-team p-0 bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div
              className="container"
              style={{
                marginTop: "5%",
                paddingLeft: "20%",
                paddingRight: "20%",
              }}
            >
              <div className="row text-center mt-4 mb-4">
                <div className="col">
                  <h1 className="mb-5 font-weight-500">
                    Leverage our team to
                    <br />
                    <span style={{ color: "#A6C853" }}>
                      Deliver High Quality Products
                    </span>
                  </h1>
                  <img
                    src="./images/Vector 5.png"
                    alt="Decorative Image"
                    className="staff-aug-bg-image"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/document-code.png" className="img-fluid" />
                    <p className="staff-card-head mt-4">Back End Development</p>
                    <p className="staff-card-small text-muted">
                      Create custom software tailored for your unique needs,
                      including front-end, and core back-end technology.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/setting-5.png" />
                    <p className="staff-card-head mt-4">Apps & Infra Monitoring</p>
                    <p className="staff-card-small text-muted">
                      Monitor applications and infrastructure to provide
                      proactive support and ensure availability.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/shapes.png" />
                    <p className="staff-card-head mt-4">DevOps</p>
                    <p className="staff-card-small text-muted">
                      Ensure applications are secure, fault tolerant and highly
                      available with our DevOps and Security engineers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/brush.png" />
                    <p className="staff-card-head mt-4">
                      Front End Design & Development
                    </p>
                    <p className="staff-card-small text-muted">
                      Create beautiful, pixel-perfect, and easy-to-use designs
                      that
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/mobile.png" className="img-fluid" />
                    <p className="staff-card-head mt-4">Mobile App Development</p>
                    <p className="staff-card-small text-muted">
                      Build performant, scalable, and secure mobile applications
                      for iOS and Android devices.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/data.png" />
                    <p className="staff-card-head mt-4">CRM</p>
                    <p className="staff-card-small text-muted">
                      Develop a robust customer suite to allow business manage
                      their consumers and customers effeciently.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="p-0 bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row grey-bg">
            <div
              className="container"
              style={{
                marginTop: "5%",
                paddingLeft: "20%",
                paddingRight: "20%",
              }}
            >
              <div className="row text-center mb-4">
                <div className="col">
                  <h1 className="mb-5 font-weight-500">
                    We Dont Just Deliver,
                    <br />
                    We <span style={{ color: "#704AF2" }}>Add Value</span>.
                  </h1>
                  <img
                    src="./images/Vector 6.png"
                    alt="Decorative Image"
                    className="staff-diff-bg-image"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card-2">
                <div className="row">
                  <div className="col p-3">
                    <p className="staff-card-head-2">Project Management</p>
                    <p className="staff-card-small-2 text-muted">
                      We bring our proven digital tool kit to manage your IT
                      project and enable visibility for you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 blue-bg staff-card-2">
                <div className="row">
                  <div className="col p-3">
                    <p className="staff-card-head-2">Agile Toolkit</p>
                    <p className="staff-card-small-2">
                      Adopt Jira and other kanban models to manage creation and
                      delivery of stories and issues with clear visibility of
                      releases.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 green-bg staff-card-2">
                <div className="row">
                  <div className="col p-3">
                    <p className="staff-card-head-2">Agile Documentation</p>
                    <p className="staff-card-small-2">
                      Create and review documentation to ensure clarity of
                      functions and features
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 green-bg staff-card-2">
                <div className="row">
                  <div className="col p-3">
                    <p className="staff-card-head-2">Collaboration Channel</p>
                    <p className="staff-card-small-2">
                      We create whatsapp based channels for informal
                      communication and collaboration between our IT team and
                      your business team.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card-2">
                <div className="row">
                  <div className="col p-3">
                    <p className="staff-card-head-2">Digital Mockups</p>
                    <p className="staff-card-small-2 text-muted">
                      We create prototypes quickly and hold regular show & tells
                      to help you see development / products in action for
                      review & feedback.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 blue-bg staff-card-2">
                <div className="row">
                  <div className="col p-3">
                    <p className="staff-card-head-2">Remote Connections</p>
                    <p className="staff-card-small-2">
                      We understand we cannot always have face to face connects.
                      So we enable remote connections and secure calls for
                      on-the-move engagements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 mb-4">
              <img src="./images/Vector 5.png" className="img-fluid w-50" />
            </div>
          </div>
        </div>
      </section>
      <section className="slider-sec py-5 bg-white" id="bespoke" data-aos="fade-up">
        <div className="container">
          <div className="row" style={{ padding: "5%", paddingTop: "0%" }}>
            <div className="col-md-5" style={{ paddingTop: "7%" }}>
              <h5 className="mb-3">
                <small>OUR APPROACH</small>
              </h5>
              <h1>Agile craft solutions</h1>
              <p>
                we focus on flexibility, collaboration, and delivering tailored
                solutions quickly
              </p>
              <p>
                <img src="./images/plus.png" className="img-fluid mr-2" />{" "}
                <img src="./images/star2.png" className="img-fluid" />
              </p>
              <h6 className="mb-3">
                We harness advanced digital tools to enhance every bespoke IT
                product development project, fostering collaboration,
                visibility, and flexibility. Our integrated platforms enable
                seamless communication among stakeholders, ensuring everyone
                stays aligned and informed throughout the development process.
              </h6>
            </div>
            <div className="col-md-7" style={{ paddingTop: "7%" }}>
              <img src="./images/Agile 1.png" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <hr style={{ marginTop: "2rem", marginBottom: "0rem" }}></hr>
    </>
  );
};

export default BeSpoke;
