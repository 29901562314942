import { useState } from "react";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({ name: "", email: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = (event) => {
    event.preventDefault();
    let isValid = true;
    let newErrors = { name: "", email: "", message: "" };

    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
      isValid = false;
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
      isValid = false;
    }
    if (!formData.message.trim()) {
      newErrors.message = "Message is required.";
      isValid = false;
    }

    setErrors(newErrors);
    if (isValid) sendMail();
  };

  const sendMail = async () => {
    setLoading(true);
    setSuccessMessage("");

    const payload = {
      user_displayName: formData.name,
      email_address: formData.email,
      html_text: formData.message,
      mailto: "tdkglobal",
    };

    try {
      const response = await fetch(
        "https://func-mail-svc-prod.azurewebsites.net/api/emailsvc?code=EHbqPrBZJYpcThLK2Lmkz6JkG0nDHWFIs5ujYIftEwtgAzFuV-uvbA%3D%3D",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        setSuccessMessage("Email sent successfully!");
        setFormData({ name: "", email: "", message: "" });
      } else {
        throw new Error("Failed to send email. Please try again.");
      }
    } catch (error) {
      setSuccessMessage("Failed to send email. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="our-team py-5 bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="sliderSubHeading">CONTACT US</p>
              <h1 className="mb-5">
                Get in touch
                <br />
                with us today!
              </h1>
            </div>
            <div className="col">
              <p>
                <img src="./images/star.png" /> &nbsp;
                <img src="./images/star2.png" />
              </p>
              <p className="our-team-p">
                Our team of experts are here to help. Whatever the time of day
                and no matter how small the question - we're here.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="card rounded-lg p-4">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/cube.png" />
                    <p className="blogHeading mt-2">Message Us</p>
                    <p className="blogSubHead">
                      Please reach out to us over email and we will be happy to
                      look into your query.
                    </p>
                    <hr style={{ marginTop: "0rem", marginBottom: "0rem" }} />
                    <p className="learnMore mt-2 ">
                      <img src="./images/email.png" className="img-fluid" />
                      hello@tdkglobal.com
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card rounded-lg p-4">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/cube2.png" />
                    <p className="blogHeading mt-2">Call Us</p>
                    <p className="blogSubHead">
                      Let's chat - nothing better than talking to another human
                      being.
                    </p>
                    <hr style={{ marginTop: "0rem", marginBottom: "0rem" }} />
                    <p className="learnMore mt-2 ">
                      <img src="./images/phone.png" className="img-fluid" />
                      +44 151 280 0512
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card rounded-lg p-4">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/cube3.png" />
                    <p className="blogHeading mt-2">Address</p>
                    <p className="blogSubHead">
                      Want to pop in? We'd love you to visit our Head Office.
                    </p>
                    <hr style={{ marginTop: "0rem", marginBottom: "0rem" }} />
                    <p className="learnMore mt-2 ">
                      <img src="./images/location.png" className="img-fluid" />
                      Matchworks, Liverpool, UK
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-team py-5 bg-white" data-aos="fade-up">
        <div
          className="container"
          style={{ paddingLeft: "20%", paddingRight: "20%" }}
        >
          <div className="row text-center">
            <div className="col">
              <p>
                <img className="mr-2" src="./images/plus.png" />
                &nbsp;
                <img src="./images/star2.png" />
              </p>
              <h1 className="mb-5">
                Feel free to send our
                <br />
                friendly team a message
              </h1>
              <h6>
                Our team of experts are here to help. Whatever the time of
              </h6>
              <h6>day and no matter how small the question - we're here.</h6>
            </div>
          </div>
          <form
            id="contactForm"
            className="mt-4 contact-form"
            onSubmit={validateForm}
          >
            <div className="row">
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <small className="text-danger">{errors.name}</small>
                )}
              </div>
              <div className="col-md-6">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <small className="text-danger">{errors.email}</small>
                )}
              </div>
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                name="message"
                rows="4"
                placeholder="Enter your message"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
              {errors.message && (
                <small className="text-danger">{errors.message}</small>
              )}
            </div>
            <div className="row">
              <div className="col">
                <button
                  type="submit"
                  className="btn btn-dark w-100"
                  disabled={loading}
                >
                  {loading ? "Sending..." : "Send Message"}
                </button>
              </div>
            </div>
            {successMessage && (
              <div className="alert alert-success mt-3">{successMessage}</div>
            )}
          </form>
        </div>
      </section>
      <section className="py-5 bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="mb-5 want-demo">Want a demo of our Saas Product?</p>
            </div>
            <div className="col">
              <p>
                <img src="./images/star.png" />
                &nbsp;
                <img src="./images/star2.png" />
              </p>
              <p>
                <a
                  className="btn btn-primary radius"
                  href="/#/saas-products"
                  style={{ fontSize: "small", padding: "2% 4%" }}
                >
                  Learn More
                </a>
                &nbsp;&nbsp;
                <a
                  className="btn btn-dark radius"
                  href="/#/book"
                  style={{ fontSize: "small", padding: "2% 4%" }}
                >
                  Book Demo
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </>
  );
};

export default Contact;
