const StaffAug = () => {
  return (
    <>
      <section className="slider-sec py-5 bg-white" data-aos="fade-up">
        <div className="">
          <div className="row ">
            <div
              className="col-md-7"
              style={{ paddingTop: "7%", paddingLeft: "9%" }}
            >
              <p className="sliderSubHeading mb-3">STAFF AUGMENTATION</p>
              <h1 className="mb-4">
                Right Talent,
                <br />
                Right on Time.
              </h1>
              <h6 className="mb-4">
                Leverage our skills & resources to bridge skill gap and
                supercharge your team. We provide ‘Expertise When You Need It,
                Flexibility As You Grow’.
              </h6>
              <a className="btn btn-dark radius" href="/#/contact">
                Contact Us
              </a>
            </div>
            <div className="col-md-5  text-right">
              <img src="./images/staff.png" className="img-fluid" />
            </div>
            <img
              src="./images/Vector 6.png"
              alt="Decorative Image"
              className="slider-image"
            />
          </div>
        </div>
      </section>

      <section className="our-team p-0 bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div
              className="container"
              style={{
                marginTop: "5%",
                paddingLeft: "20%",
                paddingRight: "20%",
              }}
            >
              <div className="row text-center mt-4 mb-4">
                <div className="col">
                  <h1 className="mb-5 font2 font-weight-500">
                    Supercharging your team with our <br />
                    <span style={{ color: "#A6C853" }}>Top Talent</span>
                  </h1>
                  <img
                    src="./images/Vector 5.png"
                    alt="Decorative Image"
                    className="staff-aug-bg-image"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/document-code.png" />
                    <p className="staff-card-head mt-4">Back End Development</p>
                    <p className="staff-card-small text-muted">
                      Create custom software tailored for your unique needs,
                      including front-end, and core back-end technology.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/setting-5.png" />
                    <p className="staff-card-head mt-4">Apps & Infra Monitoring</p>
                    <p className="staff-card-small text-muted">
                      Ensure optimal performance, uptime, and proactive issue
                      resolution using a team that is proficient in proven
                      monitoring tools.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/shapes.png" />
                    <p className="staff-card-head mt-4">DevOps</p>
                    <p className="staff-card-small text-muted">
                      Improve and shorten the systems development life cycle
                      through automating execution & operation pipelines.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/brush.png" />
                    <p className="staff-card-head mt-4">
                      Front End Design & Development
                    </p>
                    <p className="staff-card-small text-muted">
                      Create beautiful, pixel-perfect, and easy-to-use designs
                      that
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/mobile.png" />
                    <p className="staff-card-head mt-4">Mobile App Development</p>
                    <p className="staff-card-small text-muted">
                      Build performant, scalable, and secure mobile applications
                      for iOS and Android devices.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card">
                <div className="row">
                  <div className="col p-3">
                    <img src="./images/data.png" />
                    <p className="staff-card-head mt-4">CRM</p>
                    <p className="staff-card-small text-muted">
                      Onboard certified Sales force professionals to deliver and
                      govern your CRM.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="p-0 bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row grey-bg">
            <div
              className="container"
              style={{
                marginTop: "5%",
                paddingLeft: "20%",
                paddingRight: "20%",
              }}
            >
              <div className="row text-center mt-4 mb-4">
                <div className="col">
                  <h1 className="mb-5 font2 font-weight-500">
                    Empowering Teams Delivering
                    <br />
                    <span style={{ color: "#704AF2" }}>Excellence.</span>
                  </h1>
                  <img
                    src="./images/Vector 6.png"
                    alt="Decorative Image"
                    className="staff-diff-bg-image"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card-2">
                <div className="row">
                  <div className="col p-3">
                    <p className="staff-card-head-2">Faster Onboarding</p>
                    <p className="staff-card-small-2 text-muted">
                      Fill skill gaps quickly and get projects delivered
                      avoiding onboarding and training delays.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 blue-bg staff-card-2">
                <div className="row">
                  <div className="col p-3">
                    <p className="staff-card-head-2">Scalable Workforce</p>
                    <p className="staff-card-small-2">
                      Easily scale the workforce up or down based on project
                      requirements or business demands.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 green-bg staff-card-2">
                <div className="row">
                  <div className="col p-3">
                    <p className="staff-card-head-2">Access Specialised Skills</p>
                    <p className="staff-card-small-2">
                      Our team consists of seasoned experts who excel in various
                      areas of technology. access to a worldwide network of
                      professionals, often at competitive rates.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 green-bg staff-card-2">
                <div className="row">
                  <div className="col p-3">
                    <p className="staff-card-head-2">Build In-house Capability</p>
                    <p className="staff-card-small-2">
                      Add skilled experts to bring the capability in-house and
                      also transfer the know-how amongst employees.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 staff-card-2">
                <div className="row">
                  <div className="col p-3">
                    <p className="staff-card-head-2">Reduce Overhead Cost</p>
                    <p className="staff-card-small-2 text-muted">
                      Avoid long term costs like paid leaves, retention costs,
                      training costs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card rounded-lg p-4 blue-bg staff-card-2">
                <div className="row">
                  <div className="col p-3">
                    <p className="staff-card-head-2">Risk Mitigation</p>
                    <p className="staff-card-small-2">
                      Avoid the risks of overstaffing or understaffing during
                      uncertain economic or project conditions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 mb-4">
              <img src="./images/Vector 5.png" className="img-fluid w-50" />
            </div>
          </div>
        </div>
      </section>
      <section className="slider-sec py-5 bg-white" id="bespoke" data-aos="fade-up">
        <div className="container">
          <div className="row ">
            <div className="col-md-6" style={{ paddingTop: "7%" }}>
              <h5 className="mb-3">
                <small>Our APPROACH</small>
              </h5>
              <h1>R.E.A.C.T approach</h1>
              <p>
                to onboarding specialised experts at speed & seamlessly
                integrating with your team.
              </p>
              <p>
                <img src="./images/plus.png" className="img-fluid mr-2" />{" "}
                <img src="./images/star2.png" className="img-fluid" />
              </p>
              <h5 className="mb-3">
                <small>
                  TDK Global revolutionizes staff augmentation with a tailored,
                  client-centric approach that seamlessly integrates top-tier
                  talent into your team. We prioritize agility and expertise,
                  ensuring your business achieves unparalleled success and
                  scalability.
                </small>
              </h5>
            </div>
            <div className="col-md-6" style={{ paddingTop: "7%" }}>
              <ul className="list-group">
                <li className="list-group-item mb-2">
                  <p className="approach-bullet mb-1 mr-4">01.</p>
                  <p className="mb-1">Requirement</p>
                </li>
                <li className="list-group-item mb-2">
                  <p className="approach-bullet mb-1 mr-4">02.</p>
                  <p className="mb-1">Evaluate & Screen</p>
                </li>
                <li className="list-group-item mb-2">
                  <p className="approach-bullet mb-1 mr-4">03.</p>
                  <p className="mb-1">Agree tenure</p>
                </li>
                <li className="list-group-item mb-2">
                  <p className="approach-bullet mb-1 mr-4">04.</p>
                  <p className="mb-1">Connect, Onboard & Collaborate</p>
                </li>
                <li className="list-group-item mb-2">
                  <p className="approach-bullet mb-1 mr-4">05.</p>
                  <p className="mb-1">Transfer Knowledge</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <hr style={{ marginTop: "0rem", marginBottom: "0rem" }}></hr>
    </>
  );
};

export default StaffAug;
