import { useState, useRef } from "react";
import "./Career.css";

const Career = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);
  const totalImages = 8; // Update this if you add more images

  const moveCarousel = (direction) => {
    setCurrentIndex((prevIndex) => {
      let newIndex = direction === "next" ? prevIndex + 1 : prevIndex - 1;
      if (newIndex >= totalImages) newIndex = 0;
      if (newIndex < 0) newIndex = totalImages - 1;
      return newIndex;
    });
  };

  return (
    <>
      <section className="py-5" data-aos="fade-up">
        <div className="container">
          <div className="card bg-light">
            <section data-aos="fade-up">
              <div className="card hero-section">
                <div className="hero-heading">
                  <h1>Together, we can make a difference.</h1>
                  <p className="hero-heading-text">
                    Be a part of our work tribe
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <section className="py-5" data-aos="fade-up">
        <div className="container">
          <div className="card bg-light card-career" style={{ padding: "7% 10%" }}>
            <img
              src="./images/ellipse.png"
              className="career-overlay-image"
              height="40"
            />
            <p className="text-white mb-4 matter-head">
              What matters to us & will make a difference to you
            </p>
            <div className="row text-white">
              <div className="col-md-3">
                <div>
                  <img src="./images/cube3.png" />
                  <p className="matter-card-head mt-4">Inclusive Culture</p>
                  <p className="matter-card-body">
                    We have a 50:50 male:female ratio and we encourage
                    inclusiveness and one team culture.
                  </p>
                </div>
                <div className="mt-4">
                  <img src="./images/cube.png" />
                  <p className="matter-card-head mt-4">Collaborative</p>
                  <p className="matter-card-body">
                    We are all about collaboration. We do things together.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <img src="./images/career-group.png" className="img-fluid" />
                <div className="career-openess">
                  <img src="./images/cube2.png" />
                  <p className="matter-card-head mt-4">Openness</p>
                  <p className="matter-card-body">
                    All communication and discussions will be transparent and we
                    encourage employees to voice their opinions.
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div>
                  <img src="./images/cube4.png" />
                  <p className="matter-card-head mt-4">Ownership</p>
                  <p className="matter-card-body">
                    We are aware that our work impacts people and businesses, so
                    we keep to the commitments that we make.
                  </p>
                </div>
                <div className="mt-4">
                  <img src="./images/cap-new.png" />
                  <p className="matter-card-head mt-4">Flexible</p>
                  <p className="matter-card-body">
                    We offer flexibility and strike a balance between personal
                    and work life.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="slider-sec py-5 bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row text-center mt-4 mb-4">
            <div className="col">
              <p className="sliderSubHeading">JOB OPENINGS</p>
              <h1 className="mb-5">
                Join our team of
                <br />
                <span style={{ color: "#704AF2" }}>experts.</span>
              </h1>
              <img
                src="./images/Vector 6.png"
                alt="Decorative Image"
                className="staff-diff-bg-image"
              />
            </div>
          </div>
          <div className="row ">
            <div className="col-md-6" style={{ padding: "0% 10%" }}>
              <div className="card rounded-lg p-4 join-expert-card">
                <div className="row">
                  <div className="col">
                    <img src="./images/node.png" className="img-fluid w-100" />
                    <h5 className="mt-4">Node.js developer</h5>
                    <p>
                      <small>
                        Looking for a performance-driven full-stack Node.js
                        developer who can help us bring TDK Global to the next
                        level of functionality, scalability, and user
                        experience.
                      </small>
                    </p>
                    <div className="text-muted small">
                      <i className="fa fa-calendar"></i> Jan 12, 2025
                      <span className="ms-3">
                        <i className="fa fa-map-marker-alt"></i> Bangalore, IN
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <ul className="list-group">
                <li className="list-group-item mb-2 join-card">
                  <div className="row p-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="text-muted small">
                        <i className="fa fa-calendar"></i> Jan 12, 2025
                        <span className="ms-3">
                          <i className="fa fa-map-marker-alt"></i> Bangalore, IN
                        </span>
                      </div>
                      <img
                        src="./images/right-arrow.png"
                        style={{
                          position: "absolute",
                          right: "8%",
                          top: "30%",
                        }}
                        className="img-fluid"
                      />
                    </div>

                    <p className="mt-2 mb-0">
                      Business Analyst with minimum 5 years experience
                    </p>
                  </div>
                </li>
                <li className="list-group-item mb-2 join-card">
                  <div className="row p-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="text-muted small">
                        <i className="fa fa-calendar"></i> Jan 12, 2025
                        <span className="ms-3">
                          <i className="fa fa-map-marker-alt"></i> Bangalore, IN
                        </span>
                      </div>
                      <img
                        src="./images/right-arrow.png"
                        style={{
                          position: "absolute",
                          right: "8%",
                          top: "30%",
                        }}
                        className="img-fluid"
                      />
                    </div>

                    <p className="mt-2 mb-0">
                      Full Stack Developer with min 8 years experience
                    </p>
                  </div>
                </li>
                <li className="list-group-item mb-2 join-card">
                  <div className="row p-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="text-muted small">
                        <i className="fa fa-calendar"></i> Jan 12, 2025
                        <span className="ms-3">
                          <i className="fa fa-map-marker-alt"></i> Bangalore, IN
                        </span>
                      </div>
                      <img
                        src="./images/right-arrow.png"
                        style={{
                          position: "absolute",
                          right: "8%",
                          top: "30%",
                        }}
                        className="img-fluid"
                      />
                    </div>

                    <p className="mt-2 mb-0">
                      Sales Consultant with min 2 years experience
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* Carousel Section */}
      <section className="py-5 bg-white" data-aos="fade-up">
        <div className="container">
          <div className="row mt-4 mb-4">
            <div className="col-md-12">
              <h1>A Day in Life</h1>
            </div>
            <p className="pl-3">
              <img
                src="./images/star.png"
                className="img-fluid mr-2"
                alt="Star"
              />
              <img src="./images/star2.png" className="img-fluid" alt="Star" />
            </p>
          </div>

          <div className="carousel-container">
            <div
              className="carousel carousel-height"
              ref={carouselRef}
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {[...Array(totalImages)].map((_, index) => (
                <img
                  key={index}
                  src={`./images/life${index + 1}.png`}
                  className="carousel-image"
                  alt={`Life ${index + 1}`}
                />
              ))}
            </div>

            <div className="carousel-controls">
              <button
                className="carousel-control-next"
                onClick={() => moveCarousel("prev")}
              >
                <img
                  src="./images/arrow-left.png"
                  className="img-fluid"
                  alt="Previous"
                />
              </button>
              <button
                className="carousel-control-prev"
                onClick={() => moveCarousel("next")}
              >
                <img
                  src="./images/arrow-right.png"
                  className="img-fluid"
                  alt="Next"
                />
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Career;
